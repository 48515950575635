/* eslint-disable indent */
import React from 'react';
import { useTheme } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { CurrentViewModeState } from '../../../state';
import * as Icon from '../../../assets/icons';
import { Container, Wrapper, UL, List, Focus } from './ViewMode.style';
import useToggleAnimation from './hooks/useToggleAnimation';
import {
  changeToOnePageView,
  changeToScrollView,
  changeToTwoPageView,
} from '../../../helpers/paging';

function ViewMode() {
  const theme = useTheme();

  const currentViewMode = useRecoilValue(CurrentViewModeState);

  useToggleAnimation();

  const setIconColor = (isActive: boolean) => {
    if (!isActive) return theme.colorTokens.hierarchy.h2;
    return theme.colorTokens.hierarchy.h1;
  };

  const handleOneSideViewButton = () => {
    changeToOnePageView();
  };

  const handleBothSideViewButton = () => {
    changeToTwoPageView();
  };

  const handleScrollViewButton = () => {
    changeToScrollView();
  };

  return (
    <Container>
      <Wrapper>
        <UL id="viewmode-ul">
          <Focus id="viewmode-focus" />
          <List
            isActive={currentViewMode === 'both'}
            onClick={handleBothSideViewButton}
          >
            <Icon.BothSideView
              stroke={setIconColor(currentViewMode === 'both')}
            />
          </List>
          <List
            isActive={currentViewMode === 'one'}
            onClick={handleOneSideViewButton}
          >
            <Icon.OneSideView
              stroke={setIconColor(currentViewMode === 'one')}
            />
          </List>
          <List
            isActive={currentViewMode === 'scroll'}
            onClick={handleScrollViewButton}
          >
            <Icon.ScrollView
              stroke={setIconColor(currentViewMode === 'scroll')}
            />
          </List>
        </UL>
      </Wrapper>
    </Container>
  );
}

export default ViewMode;

import { atom, selector } from 'recoil';
import { BukJSON } from 'buk-viewer';

export const BookInfoState = atom<BukJSON | null>({
  key: 'BookInfo',
  default: null,
});

export const BookMetaState = selector({
  key: 'BookMeta',
  get: ({ get }) => get(BookInfoState)?.meta,
});

export const BookTocState = selector({
  key: 'BookToc',
  get: ({ get }) => get(BookInfoState)?.toc ?? [],
});

export const HasMediaMetaState = selector({
  key: 'HasMediaMeta',
  get: ({ get }) => typeof get(BookInfoState)?.meta.media !== 'undefined',
});

import React, { useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ViewerState, MediaInfoState } from '../../../../state';
import * as Icon from '../../../../assets/icons';
import { useOnClickOutside } from '../../../../hooks';
import { renderRateNumber } from '../../../../helpers';
import {
  Overlay,
  Wrapper,
  Label,
  ControlButton,
} from './PlaybackRateController.style';

interface PlaybackRateControllerProps {
  rate: number;
  step: number;
  onClose: Function;
}

function PlaybackRateController({
  rate,
  step,
  onClose,
}: PlaybackRateControllerProps) {
  const viewer = useRecoilValue(ViewerState);
  const [mediaInfo, setMediaInfo] = useRecoilState(MediaInfoState);

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  const onChangeRate = (value: number) => {
    if (value < 0.5) {
      return;
    }

    if (value > 2.0) {
      return;
    }

    setMediaInfo((prevState) => ({
      ...prevState,
      playbackRate: value,
    }));
  };

  const handleMinusButton = () => {
    onChangeRate(rate - step);
  };

  const handlePlusButton = () => {
    onChangeRate(rate + step);
  };

  useEffect(() => {
    viewer?.setMediaOverlayPlaybackRate(mediaInfo.playbackRate);
  }, [viewer, mediaInfo]);

  return (
    <>
      <Overlay />
      <Wrapper ref={ref}>
        <Label>{renderRateNumber(rate)}</Label>
        <ControlButton onClick={handleMinusButton}>
          <Icon.Minus />
        </ControlButton>
        <ControlButton onClick={handlePlusButton}>
          <Icon.Plus />
        </ControlButton>
      </Wrapper>
    </>
  );
}

export default PlaybackRateController;

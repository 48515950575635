import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon } from '@ipf-dev/web-spindle-design-system';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { PopupOpenState } from '../../../../state';
import { Item } from './types';
import { MenuForDesktop, MenuForMobile } from './Menu';
import TableOfContents from '../../../blocks/TableOfContents';
import Settings from '../../../blocks/Settings';
import Search from '../../../blocks/Search';
import Records from '../../../blocks/Records';
import ExitButton from './ExitButton';
import BookmarkButton from './BookmarkButton';
import responsive from '../../../../styles/responsive';

const Wrapper = styled.div`
  width: calc(50% - 9.2rem);
  display: flex;
  justify-content: flex-end;

  ${responsive.conditionForOnlyOneSideView} {
    width: 50%;
  }
  ${responsive.conditionForMobile} {
    width: 65%;
  }
`;

const Divider = styled.div`
  width: 0.2rem;
  height: 3.2rem;
  margin: 1.9rem 3.2rem;
  display: inline-block;
  background-color: ${({ theme }) => theme.colorTokens.hierarchy.h4};
  border-radius: 0.2rem;
`;

function RightSide() {
  const theme = useTheme();
  const { t } = useTranslation();

  const [popupOpen, setPopupOpen] = useRecoilState(PopupOpenState);
  const [isTocOpen, setTocOpen] = useState<boolean>(false);
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [isRecordsOpen, setRecordsOpen] = useState<boolean>(false);

  const onCloseSearch = () =>
    setPopupOpen((prev) => ({ ...prev, search: !popupOpen.search }));

  const menuItems: Item[] = [
    {
      name: t('epub_tool_bar_toc'),
      icon: (
        <Icon.BottomNavigation
          styleType="list"
          color={theme.colorTokens.icon.primary}
        />
      ),
      onClick: () => setTocOpen(!isTocOpen),
    },
    {
      name: t('epub_tool_bar_record'),
      icon: (
        <Icon.BottomNavigation
          styleType="record"
          color={theme.colorTokens.icon.primary}
        />
      ),
      onClick: () => setRecordsOpen(!isRecordsOpen),
    },
    {
      name: t('epub_tool_bar_view_setting'),
      icon: (
        <div id="settings-reference">
          <Icon.Navigation
            styleType="setting"
            color={theme.colorTokens.icon.primary}
            platform="mobile"
          />
        </div>
      ),
      onClick: () => setSettingsOpen(!isSettingsOpen),
    },
    {
      name: t('epub_tool_bar_search'),
      icon: (
        <Icon.Header
          styleType="search"
          color={theme.colorTokens.icon.primary}
          platform="mobile"
        />
      ),
      onClick: onCloseSearch,
    },
  ];

  return (
    <>
      <Wrapper>
        <MenuForDesktop items={menuItems} />
        <MenuForMobile items={menuItems} />
        <BookmarkButton />
        <Divider />
        <ExitButton />
      </Wrapper>
      <TableOfContents
        isTocOpen={isTocOpen}
        onClose={() => setTocOpen(!isTocOpen)}
      />
      <Settings
        id="settings-popper"
        isSettingsOpen={isSettingsOpen}
        onClose={() => setSettingsOpen(!isSettingsOpen)}
      />
      <Search isSearchOpen={popupOpen.search} onClose={onCloseSearch} />
      <Records
        isRecordsOpen={isRecordsOpen}
        onClose={() => setRecordsOpen(!isRecordsOpen)}
      />
    </>
  );
}

export default RightSide;

import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ModalDialog, Icon } from '@ipf-dev/web-spindle-design-system';
import { light } from '../../../styles/theme';

export default class ModalTrigger {
  static info(title: string, content: string, confirmLabel: string) {
    this.Modal('info', title, content, confirmLabel);
  }

  private static Modal(
    styleType: StyleType,
    title: string,
    content: string,
    confirmLabel: string,
  ) {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const close = () => {
      unmountComponentAtNode(container);
      container.parentNode?.removeChild(container);
    };

    render(
      <ModalTemplate
        styleType={styleType}
        title={title}
        content={content}
        confirmLabel={confirmLabel}
        onClose={close}
      />,
      container,
    );
  }
}

type StyleType = 'info';

interface ModalTemplateProps {
  styleType: StyleType;
  title: string;
  content: string;
  confirmLabel: string;
  onClose: Function;
}

function ModalTemplate({
  styleType,
  title,
  content,
  confirmLabel,
  onClose,
}: ModalTemplateProps) {
  return (
    <ThemeProvider theme={light}>
      <ModalDialog
        visible={true}
        onClose={() => onClose()}
        style={{ maxWidth: '48rem' }}
      >
        <ModalDialog.Template
          styleType="confirm"
          Icon={<Icon.State styleType={styleType} size="large" />}
          title={title}
          description={content}
          onConfirmLabel={confirmLabel}
          onConfirm={() => onClose()}
        />
      </ModalDialog>
    </ThemeProvider>
  );
}

import styled, { css } from 'styled-components';
import * as typography from '@tapas/typography';

export const defaultWrapperStyle = css`
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 3.8rem;

  background-color: ${({ theme }) => theme.colorTokens.background.primary};
  border-top: 0.1rem solid ${({ theme }) => theme.colorTokens.hierarchy.h5};
`;
export const leanWrapperStyle = css`
  flex-direction: column;
  justify-content: center;

  background-color: transparent;
`;

export const Wrapper = styled.div<{ styleType: string }>`
  display: flex;

  ${({ styleType }) =>
    styleType === 'default' ? defaultWrapperStyle : leanWrapperStyle}
`;

export const leanPageLabelStyle = css`
  margin-left: 0;
  margin-top: 0.4rem;
  transform: translateY(0.1rem);

  text-align: center;
  color: rgba(255, 255, 255, 0.6);
`;

export const PageLabel = styled.span<{ styleType: string }>`
  display: inline-block;
  margin-left: 1.6rem;
  white-space: nowrap;

  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${typography.caption('noto-sans', 500)};
  ${({ styleType }) => styleType === 'lean' && leanPageLabelStyle}
`;

export const leanGrayTextStyle = css`
  color: rgba(255, 255, 255, 0.6);
`;

export const GrayText = styled.span<{ styleType: string }>`
  color: ${({ theme }) => theme.colorTokens.hierarchy.h2};
  ${({ styleType }) => styleType === 'lean' && leanPageLabelStyle}
`;

import epubClient from '../clients/epub';
import rdnV2Client from '../clients/rdnV2';

export const getSignedCookies = (bid: string) =>
  epubClient.get(`/signed_cookie?bid=${bid}`);

export const getSignedCookiesByRdnV2API = (accessKey: string, bid: string) =>
  rdnV2Client.get(`/content/provider/${bid}/resource-url?bookFormat=EPUB3`, {
    withCredentials: true,
    headers: {
      accessKey,
    },
  });

import { useEffect, useRef } from 'react';
import { useThrottle } from '../../../../hooks';

function useResizeViewer() {
  const rootLayout = useRef<HTMLDivElement>(null);

  const onResizeLayout = (e: Event) => {
    const { innerHeight } = e.target as Window;
    if (!rootLayout.current) return;
    rootLayout.current.style.height = `${innerHeight}px`;
  };

  const throttleOnResizeLayout = useThrottle(onResizeLayout, 100);

  useEffect(() => {
    window.addEventListener('resize', throttleOnResizeLayout);
  }, [throttleOnResizeLayout]);

  return rootLayout;
}

export default useResizeViewer;

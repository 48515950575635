import styled, { css } from 'styled-components';

const defaultThumbStyle = css`
  background-color: ${({ theme }) => theme.colorTokens.accent.default};
`;

export const RangeInput = styled.input<{ styleType: string }>`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 0.4rem;

  background-color: #d5d5d5;
  border-radius: 8px;
  outline: none;

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 0.4rem;
  }

  &::-moz-track {
    -moz-appearance: none;
    height: 0.4rem;
  }

  &::-ms-track {
    appearance: none;
    height: 0.4rem;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    border: none;

    ${({ styleType }) => styleType === 'default' && defaultThumbStyle};
    ${({ disabled }) => disabled && 'background-color: transparent'}
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    border: none;

    ${({ styleType }) => styleType === 'default' && defaultThumbStyle};
  }

  &::-ms-thumb {
    appearance: none;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    border: none;

    ${({ styleType }) => styleType === 'default' && defaultThumbStyle};
  }
`;

/* eslint-disable no-underscore-dangle */
import { BukViewer } from 'buk-viewer';
import { debounce, detectMobileDevice } from '../../utils';

function _onSelect(context: any, contextMenuRef: any) {
  const isMobile = detectMobileDevice(window.navigator.userAgent);

  const event = context.selectionInfo.event;
  const viewer: BukViewer = document.querySelector('buk-viewer')!;
  const highlight = viewer.getHighlightsFromRange(event.selection.range)[0];
  const canPlayMediaOverlay = viewer.canPlayMediaOverlayFromRange(
    event.selection.range,
  );

  contextMenuRef.current.update({
    selectedRange: event.selection.range,
    highlightRange: highlight ? highlight.range : event.selection.range,
    annotation: highlight?.annotation,
    canPlayMediaOverlay,
  });

  if (isMobile) {
    contextMenuRef.current.MOBILE_updatePositionByRect(event.selection.rect);
  } else {
    const { clientX, clientY } = context.mouseupInfo;
    contextMenuRef.current.updatePosition(
      event.selection.rect,
      clientX,
      clientY,
    );
  }

  contextMenuRef.current.show();
}

export const onSelect = debounce(_onSelect, 200);

export function onDeselect(contextMenuRef: any) {
  contextMenuRef.current.hide();
}

import { useState, useEffect } from 'react';
import { ParsedData } from '../types';

export default function useSelectedData(
  data: ParsedData[],
  selections: Set<number>,
) {
  const [selectedData, setSelectedData] = useState<ParsedData[]>([]);

  useEffect(() => {
    const newSelectedData = data.filter((item, idx) => selections.has(idx));
    setSelectedData(newSelectedData);
  }, [data, selections]);

  return selectedData;
}

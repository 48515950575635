import styled from 'styled-components';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 104%;
  right: -2rem;
  width: 14.4rem;
  background-color: ${({ theme }) =>
    theme.epubColorTokens.speedControl.background};
  opacity: 0.9;
  border-radius: 0.8rem;
`;

export const Label = styled.span`
  width: 100%;
  display: block;
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid
    ${({ theme }) => theme.epubColorTokens.speedControl.divider};
  text-align: center;
  color: ${({ theme }) => theme.colorTokens.accent.on};

  font-family: 'noto-sans';
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.1rem;
`;

export const ControlButton = styled.button`
  width: 50%;
  padding: 0.8rem 0;

  &:last-child {
    border-left: 0.1rem solid
      ${({ theme }) => theme.epubColorTokens.speedControl.divider};
  }
`;

export const makeCustomScrollBarStyle = (
  thumbColor: string,
  borderColor: string,
) => `
  *::-webkit-scrollbar {
    width: 14px;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 16px 0;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${thumbColor};
    border: 4px solid ${borderColor};
  }
`;

import { atom, selector } from 'recoil';
import { PageInfoChangeEvent } from 'buk-viewer';

export const PageInfoState = atom<PageInfoChangeEvent>({
  key: 'PageInfo',
  default: {
    page: 0,
    pageCount: 0,
    printPageCount: null,
    printPages: [],
    tocIndex: 0,
  },
});

export const HasPrintPageInfoState = selector({
  key: 'HasPrintPageInfo',
  get: ({ get }) => get(PageInfoState).printPages.length !== 0,
});

import styled from 'styled-components';
import * as typography from '@tapas/typography';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  margin-right: 0.9rem;
  transform: translateY(0.1rem);
  cursor: default;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${({ theme }) => typography.subheading(theme.fonts.default, 700)};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as typography from '@tapas/typography';
import { GoodFace, BadFace } from '../../../../../assets/icons';

const Wrapper = styled.div`
  height: 42.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.span`
  margin-top: 0.8rem;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h3};
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 700)};
`;

type SearchInfoType = 'default' | 'notFound' | 'onlyWord';

interface SearchInfoProps {
  type: SearchInfoType;
  source?: 'original' | string;
  query?: string;
}

function SearchInfo({ type, source, query }: SearchInfoProps) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {type === 'default' && (
        <>
          <GoodFace />
          {source === 'original' && (
            <Content>{t('epub_search_extract_result_default')}</Content>
          )}
          {source !== 'original' && (
            <Content>{t('epub_search_dictionary_result_default')}</Content>
          )}
        </>
      )}
      {type === 'notFound' && (
        <>
          <BadFace />
          <Content>{t('epub_search_word_no_result', { word: query })}</Content>
        </>
      )}
      {type === 'onlyWord' && (
        <>
          <BadFace />
          <Content>{t('epub_search_no_result_search_for_single_word')}</Content>
        </>
      )}
    </Wrapper>
  );
}

export default SearchInfo;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import useResizeViewer from './hooks/useResizeViewer';

export const Layout = styled.div`
  width: 100vw;
  height: ${window.innerHeight}px;
  background-color: ${({ theme }) =>
    theme.colorTokens.mode === 'light'
      ? theme.custom.backgroundLighten
      : theme.custom.backgroundDarken};
`;

interface RootProps {
  children: ReactNode;
}

function Root({ children }: RootProps) {
  const rootLayout = useResizeViewer();

  return <Layout ref={rootLayout}>{children}</Layout>;
}

export default Root;

import React, { useState, useRef, useEffect } from 'react';
import { Theme } from 'buk-viewer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { createPopper } from '@popperjs/core';
import * as typography from '@tapas/typography';
import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';
import { ViewerState, SettingsState } from '../../../state';
import SettingFontsButton from './SettingFontsButton';
import InputStepper from './InputStepper';
import ThemeRadio from './ThemeRadio';
import SettingsFontsPopover from './SettingsFontsPopover';
import { useOnClickOutside } from '../../../hooks';
import Portal from '../../commons/Portal';
import { mobileRightSideMaxWidth } from '../../../styles/responsive';

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;

  width: 33.6rem;
  padding: 2.4rem 3.2rem;
  box-shadow: -0.3rem 1rem 4rem rgba(0, 0, 0, 0.2);
  border-radius: 1.6rem;
  background-color: ${({ theme }) => theme.colorTokens.background.secondary};

  &:after {
    content: '';
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 20px 20px;
    border-color: ${({ theme }) => theme.colorTokens.background.secondary}
      transparent;
    display: block;
    width: 0;
    z-index: 1;
  }
`;

const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 0.8rem;
  padding: 0.5rem 0;

  &:first-child {
    margin-top: 0;
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 500)}
`;

interface SettingsProps {
  id: string;
  isSettingsOpen: boolean;
  onClose: Function;
}

function Settings({ id, isSettingsOpen, onClose }: SettingsProps) {
  const viewer = useRecoilValue(ViewerState);
  const settingsState = useRecoilValue(SettingsState);
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  const isLeanHeader = useMediaQuery({ query: mobileRightSideMaxWidth });

  useEffect(() => {
    const reference = document.getElementById(
      isLeanHeader ? 'more-reference' : 'settings-reference',
    );
    const popper = document.getElementById('settings-popper');
    if (!reference || !popper) return;
    createPopper(reference, popper, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 40],
          },
        },
      ],
    });
  });

  const onChangeTheme = (theme: 'light' | 'dark') => {
    viewer?.changeTheme(theme as Theme);

    if (theme === 'dark') {
      document.querySelector('#context-menu')?.classList.add('dark');
    } else {
      document.querySelector('#context-menu')?.classList.remove('dark');
    }
  };

  const onChangeFonts = (fontFace: string) => {
    viewer?.changeFontFace(fontFace);
  };

  const onStepForFontSize = (value: number) => {
    viewer?.setPageAnimation(false);
    viewer?.changeFontSize(value, true);
    viewer?.setPageAnimation(true);
  };

  const onStepForLineHeight = (value: number) => {
    viewer?.setPageAnimation(false);
    if (value === 90) viewer?.changeLineHeight(95, true);
    if (value === 80) viewer?.changeLineHeight(90, true);
    if (value === 70) viewer?.changeLineHeight(85, true);
    if (value >= 100) viewer?.changeLineHeight(value, true);
    viewer?.setPageAnimation(true);
  };

  const formatInputStepperLabel = (value: number) => `${(value - 100) / 10}`;

  return (
    <Portal rootId="modal-root">
      {isSettingsOpen && (
        <>
          <Overlay />
          <Wrapper id={id} ref={ref}>
            <ItemBox>
              <Label>{t('epub_view_setting_theme')}</Label>
              <ThemeRadio
                theme={settingsState?.theme.value ?? 'light'}
                onChangeTheme={onChangeTheme}
              />
            </ItemBox>
            <ItemBox>
              <Label>{t('epub_view_setting_font')}</Label>
              <SettingFontsButton
                currentFontFace={
                  settingsState?.fontFace.value ??
                  t('epub_view_setting_original_font')
                }
                onClick={() => {
                  setPopoverOpen(!isPopoverOpen);
                  onClose();
                }}
              />
            </ItemBox>
            <ItemBox>
              <Label>{t('epub_view_setting_text_size')}</Label>
              <InputStepper
                min={50}
                max={200}
                step={10}
                defaultValue={settingsState?.fontSize.value ?? 100}
                onStep={onStepForFontSize}
                onFormattingLabel={formatInputStepperLabel}
              />
            </ItemBox>
            <ItemBox>
              <Label>{t('epub_view_setting_line_height')}</Label>
              <InputStepper
                min={70}
                max={150}
                step={10}
                defaultValue={settingsState?.lineHeight.value ?? 100}
                onStep={onStepForLineHeight}
                onFormattingLabel={formatInputStepperLabel}
              />
            </ItemBox>
          </Wrapper>
        </>
      )}
      {isPopoverOpen && (
        <>
          <Overlay />
          <SettingsFontsPopover
            currentFont={settingsState?.fontFace.value ?? 'default'}
            fonts={viewer?.getFontsCurrentlyAvailable() ?? ['default']}
            onChangeFonts={onChangeFonts}
            onClose={() => setPopoverOpen(!isPopoverOpen)}
            onToggleSettings={() => onClose()}
          />
        </>
      )}
    </Portal>
  );
}

export default Settings;

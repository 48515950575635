/* eslint-disable class-methods-use-this */
import { v4 as uuidv4 } from 'uuid';
import localforage from '../lib/localforage';

class Annotations {
  constructor(bid, { onChange }) {
    this.bid = bid;
    this.onChange = onChange;

    this.getAll().then((annotations) => {
      this._annotations = annotations;

      this.onChange?.(this._annotations);
    });
  }

  destroy() {
    this.onChange = null;
  }

  getAll() {
    return this._getAll();
  }

  async create(viewerAnnotation, context) {
    const printPages = context.current.pageInfo.printPages;
    const makePrintPage = () => {
      const { length } = printPages;

      if (length === 1) {
        return printPages[0];
      }
      if (length > 1) {
        return `${printPages[0]}-${printPages[length - 1]}`;
      }

      return null;
    };

    const customField = {
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      printPage: makePrintPage(),
      _id: uuidv4(),
    };

    const annotation = {
      ...viewerAnnotation,
      text: viewerAnnotation.text.trim(),
      ...customField,
    };

    this._annotations.push(annotation);
    this.onChange?.(this._annotations);

    try {
      await this._set(annotation);
    } catch (error) {
      // rollback
    }
  }

  update(viewerAnnotation) {
    const index = this._annotations.findIndex(
      (_annotation) => _annotation.url === viewerAnnotation.url,
    );

    if (index === -1) {
      return;
    }

    const annotation = {
      ...this._annotations[index],
      ...viewerAnnotation,
      updatedAt: new Date().getTime(),
    };

    this._annotations[index] = annotation;
    this.onChange?.(this._annotations);

    try {
      this._set(annotation);
    } catch (error) {
      // rollback
    }
  }

  async delete(viewerAnnotationURLs) {
    let annotationURLS = viewerAnnotationURLs;

    if (typeof annotationURLS === 'number') {
      annotationURLS = [viewerAnnotationURLs];
    }

    const [annotations, removedAnnotations] = this._annotations.reduce(
      (result, annotation) => {
        if (annotationURLS.includes(annotation.url)) {
          result[1].push(annotation);
        } else {
          result[0].push(annotation);
        }
        return result;
      },
      [[], []],
    );

    this._annotations = annotations;
    this.onChange?.(this._annotations);

    try {
      await Promise.all(
        removedAnnotations.map((annotation) => this._remove(annotation._id)),
      );
    } catch (error) {
      // rollback
    }
  }

  async _getAll() {
    const length = await localforage.length();
    const KeyNamePromises = Array.from({ length }).map(async (value, index) => {
      const keyName = await localforage.key(index);
      return keyName;
    });
    const keyNames = await Promise.all(KeyNamePromises);

    const resultPromises = keyNames
      .filter((key) => {
        const keyRegex = new RegExp(`^annotations.${this.bid}.[a-z0-9-]+$`);
        const flag = keyRegex.test(key);
        return flag;
      })
      .map(async (key) => ({
        ...JSON.parse((await localforage.getItem(key)) ?? ''),
        _id: key.split('.')[2],
      }));
    const result = await Promise.all(resultPromises);
    return result;
  }

  _set(annotation) {
    localforage.setItem(
      this._getItemKey(annotation._id),
      JSON.stringify(annotation),
    );
  }

  _remove(id) {
    localforage.removeItem(this._getItemKey(id));
  }

  _getItemKey(id) {
    return `annotations.${this.bid}.${id}`;
  }

  // CUSTOM STATIC METHODS
  static async CUSTOM_getAll(bid) {
    const length = await localforage.length();
    const KeyNamePromises = Array.from({ length }).map(async (value, index) => {
      const keyName = await localforage.key(index);
      return keyName;
    });
    const keyNames = await Promise.all(KeyNamePromises);

    const resultPromises = keyNames
      .filter((key) => {
        const keyRegex = new RegExp(`^annotations.${bid}.[a-z0-9-]+$`);
        const flag = keyRegex.test(key);
        return flag;
      })
      .map(async (key) => ({
        ...JSON.parse((await localforage.getItem(key)) ?? ''),
        _id: key.split('.')[2],
      }));
    const result = await Promise.all(resultPromises);
    return result;
  }

  static async CUSTOM_get(bid, id) {
    return JSON.parse(await localforage.getItem(`annotations.${bid}.${id}`));
  }

  static CUSTOM_set(bid, id) {
    localforage.setItem(`annotations.${bid}.${id}`);
  }

  static CUSTOM_remove(bid, id) {
    localforage.removeItem(`annotations.${bid}.${id}`);
  }
}

export default Annotations;

import React, { useState, useLayoutEffect } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon, Control, ModalDialog } from '@ipf-dev/web-spindle-design-system';
import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';
import { BookMetaState } from '../../../../state';
import { tabletQuery } from '../../../../styles/responsive';
import Tab from '../../../commons/Tab';
import Dictionary from '../../Search/Dictionary';
import Sentence from '../../Search/SearchResultList/SearchResultList';
import Words from '../../../../models/Words';
import { Wrapper, Label } from './WordDetails.style';
import Toast from '../../../commons/EpubToast';

function WordInfoHeaderLight({ _id, word, onDelete, onUpdate }: any) {
  const theme = useTheme();
  const { t } = useTranslation();

  const bookMeta = useRecoilValue(BookMetaState);
  const [checked, setChecked] = useState(false);
  const wordsModel = new Words(bookMeta!.bid, () => {});
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onChange = async () => {
    setChecked(!checked);
    const word = await wordsModel.get(_id);
    word.remembered = !checked;
    wordsModel.update(word);
  };

  useLayoutEffect(() => {
    wordsModel.get(_id).then((word) => setChecked(word.remembered));
  }, []);

  const handleClickTrash = () => setIsDeleting(!isDeleting);

  return (
    <>
      <Wrapper>
        <Label>Got it!</Label>
        <Control.Switch
          name="remembered"
          value=""
          size="medium"
          checked={checked}
          onChange={onChange}
        />
        <Icon.State
          styleType="trash"
          size="medium"
          color={theme.colorTokens.mode === 'light' ? '#000' : '#fff'}
          onClick={handleClickTrash}
          style={{ marginLeft: '2.4rem' }}
        />
      </Wrapper>
      <ModalDialog
        visible={isDeleting}
        onClose={() => setIsDeleting(false)}
        style={{
          maxWidth: '48rem',
        }}
      >
        <ModalDialog.Template
          styleType="warning"
          Icon={<Icon.State styleType="trash" size="large" />}
          title={word}
          description={t('epub_record_tab_vocabulary_delete_modal_text')}
          onCancelLabel={t('epub_record_confirm_modal_cancel_label')}
          onConfirmLabel={t('epub_record_confirm_modal_delete_label')}
          onCancel={() => setIsDeleting(false)}
          onConfirm={onDelete}
        />
      </ModalDialog>
    </>
  );
}

interface WordDetailsProps {
  _id: string;
  word: string;
  visible: boolean;
  onClose: Function;
  onDeleteWords: Function;
}

function WordDetails({
  _id,
  word,
  visible,
  onClose,
  onDeleteWords,
}: WordDetailsProps) {
  const isTablet = useMediaQuery({ query: tabletQuery });
  const theme = useTheme();

  const { t } = useTranslation();

  const deleteWord = () => {
    onDeleteWords([_id]);
    Toast().success(t('epub_toast_delete_word', { count: 1 }));
  };

  const updateWord = () => {};

  return (
    <ModalDialog
      styleType="header"
      headerOptions={{
        title: ' ',
        left: {
          label: (
            <Icon.Arrow
              styleType="direction-sign"
              direction="left"
              color={theme.colorTokens.icon.primary}
            />
          ),
          onClick: () => onClose(),
        },
        right: {
          label: (
            <WordInfoHeaderLight
              _id={_id}
              word={word}
              onDelete={() => {
                deleteWord();
                onClose();
              }}
              onUpdate={updateWord}
            />
          ),
          onClick: () => undefined,
        },
        // style: {
        //   borderBottom: 'none',
        // },
      }}
      visible={visible}
      onClose={() => onClose()}
      style={{
        height: '64rem',
        width: `${isTablet ? 'calc(100% - 12.8rem)' : 'calc(100% - 6.4rem)'}`,
        zIndex: 8000,
        maxWidth: '64rem',
      }}
    >
      <Tab
        tabItems={[
          {
            id: 'tab1',
            title: t('epub_record_tab_vocabulary_dictionary'),
            contents: <Dictionary word={word} from="word-details" />,
          },
          {
            id: 'tab2',
            title: t('epub_record_tab_vocabulary_sample_sentences'),
            contents: (
              <Sentence
                source="example"
                query={word}
                onClose={onClose}
                from="word-details"
              />
            ),
          },
        ]}
        styleType="fullwidth"
        options={{
          tabNavItemStyle: {
            color: `${theme.colorTokens.hierarchy.h1}`,
            margin: 0,
          },
        }}
      />
    </ModalDialog>
  );
}

export default WordDetails;

import { atom } from 'recoil';

interface PopupOpen {
  search: boolean;
  header: boolean;
  playButton: boolean;
  media: boolean;
  progressBar: boolean;
  contextMenu: boolean;
}

export default atom<PopupOpen>({
  key: 'PopupOpenState',
  default: {
    search: false,
    header: true,
    playButton: true,
    media: true,
    progressBar: true,
    contextMenu: false,
  },
});

import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import * as typography from '@tapas/typography';
import * as Icon from '../../../assets/icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10rem;
  background-color: ${({ theme }) => theme.colorTokens.hierarchy.h5};
`;

const StepControlButton = styled.button.attrs({ type: 'button' })`
  height: 3.7rem;
  padding: 0.7rem 0.9rem;

  &:first-child {
    border-right: 0.1rem solid transparent;
    border-color: ${({ theme }) => theme.colorTokens.hierarchy.h4};
  }
  &:last-child {
    border-left: 0.1rem solid transparent;
    border-color: ${({ theme }) => theme.colorTokens.hierarchy.h4};
  }
`;

const CurrentStepLabel = styled.span`
  width: 6.6rem;
  padding: 0 1.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${typography.body('noto-sans', 1.6, 500)}
`;

type Direction = 'up' | 'down' | null;

interface StateType {
  value: number;
  direction: Direction;
}

interface InputStepperProps {
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  onStep: (value: number) => void;
  onFormattingLabel?: (value: number) => string;
}

function InputStepper({
  min,
  max,
  step,
  defaultValue,
  onStep,
  onFormattingLabel,
}: InputStepperProps) {
  const theme = useTheme();

  const [state, setState] = useState<StateType>({
    value: defaultValue,
    direction: null,
  });

  const onClick = (direction: Direction) => {
    const nextValue =
      direction === 'up' ? state.value + step : state.value - step;

    if (nextValue < min) {
      return;
    }

    if (nextValue > max) {
      return;
    }

    setState({
      value: nextValue,
      direction,
    });
  };

  useEffect(() => {
    onStep(state.value);
  }, [state.value]);

  return (
    <Wrapper>
      <StepControlButton onClick={() => onClick('down')}>
        <Icon.Minus fill={theme.colorTokens.hierarchy.h1} />
      </StepControlButton>
      <CurrentStepLabel>
        {onFormattingLabel ? onFormattingLabel(state.value) : state.value}
      </CurrentStepLabel>
      <StepControlButton onClick={() => onClick('up')}>
        <Icon.Plus fill={theme.colorTokens.hierarchy.h1} />
      </StepControlButton>
    </Wrapper>
  );
}

export default InputStepper;

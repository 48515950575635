/*eslint-disable*/
import css from './style';
import { CONTEXT_MENU_ACTIONS } from '../../../lib/buk-viewer/viewer.config';
import { t } from 'i18next';
import { detectAppleMobileDevice } from '../../../utils';

const makeContextMenuStyle = () => {
  const themeStyle = document.createElement('style');
  document.head.appendChild(themeStyle);
  themeStyle.appendChild(document.createTextNode(css));
};

class ContextMenu {
  constructor(
    element,
    highlightStyles,
    { onHighlightMenuClick, onMenuClick, onChange },
  ) {
    makeContextMenuStyle();

    this.context = null;
    this.menuSpacing = 10;

    this.element = element;
    this.onHighlightMenuClick = onHighlightMenuClick;
    this.onMenuClick = onMenuClick;
    this.onChange = onChange;

    this.highlightsBox = document.createElement('div');
    this.highlightsBox.classList.add('highlights-box');
    this.element.appendChild(this.highlightsBox);

    this.actionsBox = document.createElement('div');
    this.actionsBox.classList.add('actions-box');
    this.element.appendChild(this.actionsBox);

    this.isAppleMobile = detectAppleMobileDevice(window.navigator.userAgent);

    // 하이라이트 메뉴 초기화

    highlightStyles.forEach((highlightStyle) => {
      const element = this._createHighlightMenuElem(highlightStyle);

      element.addEventListener('click', (event) => {
        this.onHighlightMenuClick?.({
          context: this.context,
          styleClass: event.currentTarget.dataset.styleClass,
        });
      });

      this.highlightsBox.appendChild(element);
    });

    // 하이라이트 삭제, 기타 메뉴 초기화
    CONTEXT_MENU_ACTIONS.forEach((action) => {
      const element = this._createMenuElem('menu-action');

      element.dataset.action = action;
      element.id = action;
      element.innerText = t(
        `epub_contextmenu_${action === 'listen' ? 'read_aloud' : action}`,
      );

      element.addEventListener('click', (event) => {
        this.onMenuClick?.({
          action: event.currentTarget.dataset.action,
          context: this.context,
        });
      });

      this.actionsBox.appendChild(element);
    });
  }

  show() {
    this.element.classList.add('is-active');
    this.onChange(true);
  }

  hide() {
    this.element.classList.remove('is-active');
    this.onChange(false);
  }

  /**
   * @returns { HTMLDivElement } 생성된 엘리먼트
   */
  _createMenuElem(type) {
    const element = document.createElement('div');

    element.classList.add(`${type}-item`);
    element.classList.add('menu-item');

    return element;
  }

  /**
   * @param { { styleClass: string, style: string } } highlightStyle 하이라이트 스타일 정의
   * @returns { HTMLDivElement } 생성된 엘리먼트
   */
  _createHighlightMenuElem(highlightStyle) {
    const [styleClass, style] = highlightStyle;
    const element = this._createMenuElem('menu-highlight');

    element.dataset.styleClass = styleClass;

    const previewElement = document.createElement('div');

    previewElement.classList.add('menu-highlight-preview');
    previewElement.classList.add('menu-item');
    previewElement.style = `background-color: ${style};`;

    element.appendChild(previewElement);

    return element;
  }

  /**
   * @param {Object} context
   * @param {Range} context.selectedRange - 실제 사용자가 선택한 Range
   * @param {Range} context.highlightRange - 하이라이트 동작에 필요한 Range
   * @param {boolean} context.canPlayMediaOverlay - selectedRange를 이용한 미디어 오버레이 재생 가능 여부
   * @param {Annotation=} context.annotation - 현재 선택을 포함하고 있는 Annotation
   */
  update(context) {
    this.context = context;
    const { theme } = window;

    if (theme === 'dark') {
      this.element.classList.add('dark');
    } else {
      this.element.classList.remove('dark');
    }

    // 기존 메뉴 상태 리셋
    this.element
      .querySelectorAll('.menu-item.is-selected')
      .forEach((menuElem) => {
        menuElem.classList.remove('is-selected');
      });
    this.element
      .querySelectorAll('.menu-item.is-disabled')
      .forEach((menuElem) => {
        menuElem.classList.remove('is-disabled');
      });

    // 하이라이트 관련 메뉴 셋팅
    if (this.context.annotation) {
      this.element
        .querySelector(
          `.menu-highlight-item[data-style-class="${this.context.annotation.styleClass}"]`,
        )
        ?.classList.add('is-selected');
    } else {
      this.element
        .querySelector('.menu-item[data-action="delete"]')
        .classList.add('is-disabled');
    }

    if (!this.context.canPlayMediaOverlay) {
      this.element
        .querySelector('.menu-item[data-action="listen"]')
        .classList.add('is-disabled');
    }
  }

  /**
   * @param { { x: number; y: number; width: number; height: number} } rect
   */
  updatePosition(rect, clientX, clientY) {
    const menuRect = this.element.getBoundingClientRect();

    const left = clientX - menuRect.width / 2;
    let top = rect.y + rect.height;

    if (top > window.innerHeight) {
      top = rect.y - menuRect.height;
    }

    this._updatePosition(left, top, rect.height);
  }

  /**
   * @param { number } offsetX
   * @param { number } offsetY
   * @param { Range } range 선택 Range
   */
  updatePositionByOffsetAndRange(offsetX, offsetY, range, rect) {
    const menuRect = this.element.getBoundingClientRect();
    const left = offsetX - menuRect.width / 2;

    const rangeRects = Array.from(range.getClientRects());
    const maxLineHeight = Math.max(...rangeRects.map((rect) => rect.height));

    const top = offsetY + maxLineHeight * 0.5;

    this._updatePosition(left, top, maxLineHeight);
  }

  /**
   * @param { number } left
   * @param { number } top
   */
  _updatePosition(left, top, selectionHeight) {
    let right, bottom;

    const menuRect = this.element.getBoundingClientRect();
    const OVER_HEIGHT_REVISION = 20;

    if (left < 0) {
      left = 0;
      right = undefined;
    } else if (left + menuRect.width + this.menuSpacing > window.innerWidth) {
      left = undefined;
      right = 0;
    }

    if (top < 0) {
      top = 0;
      bottom = undefined;
    } else if (top + menuRect.height + this.menuSpacing > window.innerHeight) {
      top -= menuRect.height + selectionHeight + OVER_HEIGHT_REVISION;
      bottom = undefined;
    }

    Object.entries({
      top,
      right,
      bottom,
      left,
    }).forEach(([key, value]) => {
      if (value != null) {
        this.element.style.setProperty(key, value + this.menuSpacing + 'px');
      } else {
        this.element.style.removeProperty(key);
      }
    });
  }

  MOBILE_updatePositionByRect(rect) {
    const menuRect = this.element.getBoundingClientRect();

    const left = rect.x + (rect.width - menuRect.width) / 2;

    let top = rect.y + rect.height;

    if (top > window.innerHeight) {
      top = rect.y - menuRect.height;
    }

    this.MOBILE_updatePosition(left, top, rect);
  }

  MOBILE_updatePosition(left, top, rect) {
    let right, bottom;

    const menuRect = this.element.getBoundingClientRect();

    if (left < 0) {
      left = 0;
      right = undefined;
    } else if (left + menuRect.width + this.menuSpacing > window.innerWidth) {
      left = undefined;
      right = 0;
    }

    if (top < 0) {
      top = 0;
      bottom = undefined;
    } else if (top + menuRect.height + this.menuSpacing > window.innerHeight) {
      const appleDefaultContextMenuHeight = 50;
      const defaultRevision = 25;
      const revision = this.isAppleMobile
        ? defaultRevision + appleDefaultContextMenuHeight
        : defaultRevision;

      top = rect.y - menuRect.height - revision;
      bottom = undefined;
    }

    Object.entries({
      top,
      right,
      bottom,
      left,
    }).forEach(([key, value]) => {
      if (value != null) {
        this.element.style.setProperty(key, value + this.menuSpacing + 'px');
      } else {
        this.element.style.removeProperty(key);
      }
    });
  }
}

export default ContextMenu;

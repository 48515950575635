import React, { useRef, useEffect, useState } from 'react';
import { createPopper } from '@popperjs/core';
import { useOnClickOutside } from '../../../../hooks';
import ColorSelectBox from '../ColorSelectBox';
import { Overlay, Wrapper, ActionButton } from './RecordPopover.style';
import { RecordPopoverProps } from './types';

function RecordPopover({
  idx,
  recordsType,
  styleClass,
  onClose,
  onChangeColor,
  actions,
}: RecordPopoverProps) {
  const [selectedColor, onSelect] = useState<string>(styleClass);

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  useEffect(() => {
    const reference = document.getElementById(`popover-reference-${idx}`);
    const popper = document.getElementById(`popover-popper-${idx}`);

    if (!reference || !popper) return;

    createPopper(reference, popper as HTMLElement, {
      placement: 'top-start',
    });

    popper.style.display = 'block';
  }, [idx]);

  const onSelectColor = (color: string) => {
    onSelect(color);
    onChangeColor(color);
    onClose();
  };

  return (
    <>
      <Overlay />
      <Wrapper id={`popover-popper-${idx}`} ref={ref}>
        {recordsType === 'highlight' && (
          <ColorSelectBox
            styleType="palette"
            selectedColor={selectedColor}
            onSelect={onSelectColor}
          />
        )}
        {actions.map(({ name, onAct }, itemIdx) => (
          <ActionButton
            key={`popover-cation${itemIdx + name}`}
            onClick={() => {
              onAct();
              onClose();
            }}
          >
            {name}
          </ActionButton>
        ))}
      </Wrapper>
    </>
  );
}

export default RecordPopover;

import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import MenuItem from './MenuItem';
import responsive from '../../../../../styles/responsive';
import Dropdown from '../../../../commons/Dropdown';
import * as Icon from '../../../../../assets/icons';
import { Item } from '../types';

const Wrapper = styled.div`
  display: none;

  ${responsive.conditionForMobileRightSide} {
    display: inline-block;
  }
`;

interface MenuForMobileProps {
  items: Item[];
}

function MenuForMobile({ items }: MenuForMobileProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const onCloseDropdown = () => setDropdownOpen(!isDropdownOpen);

  return (
    <Wrapper>
      <MenuItem
        layout="desktop"
        name={t('epub_tool_bar_more')}
        onClick={() => {
          setDropdownOpen(!isDropdownOpen);
        }}
      >
        <div id="more-reference">
          <Icon.More fill={theme.colorTokens.icon.primary} />
        </div>
      </MenuItem>
      {isDropdownOpen && <Dropdown items={items} onClose={onCloseDropdown} />}
    </Wrapper>
  );
}

export default MenuForMobile;

import React, { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { TabNavProps, TabProps } from './types';
import { TabNavItem, TabNavContainer } from './style';

function TabNav({
  tabNavs,
  selectedTabId,
  onClick,
  fullWidth = false,
  options,
}: TabNavProps) {
  return (
    <ScrollContainer className="scroll-container">
      <TabNavContainer role="tablist" fullWidth={fullWidth}>
        {tabNavs.map(({ id, title }) => (
          <TabNavItem
            id={id}
            key={id}
            active={id === selectedTabId}
            role="tab"
            onClick={() => onClick(id)}
            style={options?.tabNavItemStyle}
          >
            {title}
          </TabNavItem>
        ))}
      </TabNavContainer>
    </ScrollContainer>
  );
}

function Tab({ styleType, tabItems, options }: TabProps) {
  const [selectedTabId, setSelectedTabId] = useState(tabItems[0].id);

  return (
    <div>
      <TabNav
        tabNavs={tabItems.map(({ id, title }) => ({ id, title }))}
        selectedTabId={selectedTabId}
        onClick={(id) => setSelectedTabId(id)}
        fullWidth={styleType === 'fullwidth'}
        options={options}
      />
      <div role="tabpanel">
        {tabItems.find((item) => item.id === selectedTabId)?.contents}
      </div>
    </div>
  );
}

export default Tab;

import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import * as typography from '@tapas/typography';

const desktopMenuButtonStyle = css`
  padding: 1.4rem 0;
`;

const mobileMenuButtonStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem;
`;

const MenuButton = styled.button<{ layout: string }>`
  ${({ layout }) => layout === 'desktop' && desktopMenuButtonStyle}

  ${({ layout }) => layout === 'mobile' && mobileMenuButtonStyle}

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const Span = styled.span<{ layout: string }>`
  display: ${({ layout }) => (layout === 'desktop' ? 'block' : 'inline-block')};
  margin-left: ${({ layout }) => (layout === 'desktop' ? '0' : '0.8rem')};

  ${({ theme }) => typography.caption(theme.fonts.default, 500)}

  ${({ layout, theme }) =>
    layout === 'mobile' && typography.body(theme.fonts.default, 1.4, 400)};

  color: ${({ theme }) => theme.colorTokens.text.primary};
`;

interface MenuItemProps {
  layout: 'desktop' | 'mobile';
  name: string;
  onClick: MouseEventHandler;
  children: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
}

function MenuItem({
  layout,
  name,
  onClick,
  children,
  style,
  disabled,
}: MenuItemProps) {
  return (
    <MenuButton
      type="button"
      layout={layout}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
      <Span layout={layout}>{name}</Span>
    </MenuButton>
  );
}

export default MenuItem;

import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { HasMediaMetaState, PopupOpenState } from '../../../state';
import Media from '../../blocks/Media';
import PageNavigator from '../../blocks/PageNavigator';

const Container = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
`;

function Footer() {
  const hasMediaMeta = useRecoilValue(HasMediaMetaState);
  const popupOpen = useRecoilValue(PopupOpenState);

  return (
    <Container>
      <Media visible={popupOpen.media && hasMediaMeta} />
      <PageNavigator
        visible={popupOpen.progressBar && popupOpen.playButton}
        styleType="default"
      />
    </Container>
  );
}

export default Footer;

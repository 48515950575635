import invariant from 'tiny-invariant';
import configureViewer from '../../lib/buk-viewer';
import { parseBukURL } from '../../helpers';
import ShareInfo from '../../models/ShareInfo';
import {
  getUserBookList,
  getSignedCookies,
  getSignedCookiesByRdnV2API,
} from '../../api';

export async function startInDevMode(setters: any, refs: any) {
  const { fullURL, bid } = parseBukURL(document.location.pathname);

  try {
    if (process.env.REACT_APP_PROTOCOL !== 'http') {
      await getSignedCookies(bid);
    }
    configureViewer(fullURL, setters, refs, bid, true);
  } catch (err: any) {
    invariant(false, err);
  }
}

export function connectWithBookshelf(setters: any, refs: any) {
  const protocol = {
    IDENTIFIER: 'BOOKSHELF_E3V_MESSAGE',
    state: {
      ready: 'ready',
      done: 'done',
    },
  } as const;

  window.opener.postMessage(
    {
      identifier: protocol.IDENTIFIER,
      state: protocol.state.ready,
    },
    window.document.referrer,
  );

  window.addEventListener('message', (e) => {
    if (e.data.identifier === protocol.IDENTIFIER) {
      window.accessKey = e.data.accessKey;
      window.cmsid = e.data.bookData.book.id;
      window.bookData = e.data.bookData;
      let epubid = `${e.data.bookData.download.epubContentId}`;

      console.debug('data from bookshelf', e.data);

      if (e.data.share) {
        const { bid, iid, anchor, type, color } = e.data.share;

        if (color.length === 0) {
          ShareInfo.save({ bid, iid, anchor, type, color: 'default' });
        } else {
          ShareInfo.save({ bid, iid, anchor, type, color });
        }

        const openURL = `/${bid}/${iid}/${anchor}`;
        getContentsAuthority(setters, refs, epubid, openURL);
      } else {
        epubid = `@${epubid}`;
        getContentsAuthority(setters, refs, epubid, epubid);
      }

      window.opener.postMessage(
        {
          identifier: protocol.IDENTIFIER,
          state: protocol.state.done,
        },
        window.document.referrer,
      );
    }
  });

  window.resizeTo(window.screen.availWidth, window.screen.availHeight);
}

export const getContentsAuthority = async (
  setters: any,
  refs: any,
  epubid: string,
  url: string,
) => {
  // FIXME
  let hasBook = false;
  const { accessKey, cmsid } = window;

  try {
    const result: any = await getUserBookList(accessKey, cmsid);

    hasBook = true;
  } catch (e) {
    console.error(e);
    hasBook = false;
  }

  try {
    window.location.hostname.includes('readingn.com')
      ? await getSignedCookiesByRdnV2API(accessKey, cmsid)
      : await getSignedCookies(epubid);

    configureViewer(url, setters, refs, epubid, hasBook);
  } catch (err: any) {
    invariant(false, err);
  }
};

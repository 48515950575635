import { useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ViewerState } from '../../../../state';
import { loadExampleSentences } from '../../../../api';
import { useDebounce } from '../../../../hooks';

function useSearch(
  text: string,
  source: 'original' | 'example',
  word: string,
  onSetSentences: Function,
) {
  const viewer = useRecoilValue(ViewerState);
  // const [isLoading, setIsLoading] = useRecoilState(LoadingState);

  let cancelSearch: any;

  // 본문 검색에 필요한 함수들
  const onSearch = useCallback(async (keyword: string) => {
    if (typeof cancelSearch === 'function') {
      cancelSearch();
    }

    // setIsLoading(true);

    if (typeof Promise !== 'undefined') {
      const { promise, cancel } = searchPromise(keyword);
      cancelSearch = cancel;

      try {
        onSearchResult(keyword, await promise);
      } catch (error) {
        console.error('onSearchPromise error', error);
      }
    } else {
      cancelSearch = search(keyword, (result: any) => {
        onSearchResult(keyword, result);
      });
    }
  }, []);

  function searchPromise(keyword: string) {
    let cancel;

    return {
      promise: new Promise((resolve, reject) => {
        const onCancel = () =>
          viewer!.search(keyword, (result: any) => resolve(result));
        onCancel();

        cancel = () => {
          onCancel();
          reject();
        };
      }),
      cancel, // 상태에 담을 수 없는 이슈
    };
  }

  function search(keyword: string, callback: any) {
    return viewer!.search(keyword, (result: any) => callback(result));
  }

  function onSearchResult(keyword: string, result: any) {
    onSetSentences(result);
    // setIsLoading(false);
    cancelSearch = null;
  }

  const debouncedSearchQuery = useDebounce(text, 500);

  useEffect(() => {
    if (!debouncedSearchQuery) return;

    source === 'original' && onSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery, source, onSearch]);

  useEffect(() => {
    if (word.length === 0) return;

    source === 'example' &&
      loadExampleSentences(word).then((res: any) => {
        if (!res.data.wordSentences) {
          onSetSentences([]);
        } else {
          onSetSentences(res.data.wordSentences);
        }
      });
  }, [source, word, onSetSentences, onSearch]);
}

export default useSearch;

import React from 'react';
import { useRecoilValue } from 'recoil';
import { BookmarkState } from '../../../state';
import LeftSide from './LeftSide';
import CenterSide from './CenterSide';
import RightSide from './RightSide';
import * as Icon from '../../../assets/icons';
import { HeaderProps } from './types';
import { Container, Wrapper, BookmarkInfo } from './Header.style';

function Header({ visible }: HeaderProps) {
  const isBookmarkActive = useRecoilValue(BookmarkState);

  return (
    <Container>
      <Wrapper visible={visible}>
        <LeftSide />
        <CenterSide />
        <RightSide />
      </Wrapper>
      <BookmarkInfo visible={!visible && isBookmarkActive}>
        <Icon.BookmarkHeader />
      </BookmarkInfo>
    </Container>
  );
}

export default Header;

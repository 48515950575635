import ClipboardJS from 'clipboard';

export * from './bukio';
export * from './regex';

export function copyToClipboard(selectedText: string) {
  console.log(selectedText);

  let result;

  const textarea = document.createElement('textarea');
  textarea.id = 'copiee';
  textarea.innerText = selectedText;

  const button = document.createElement('button');
  button.className = 'copier';
  button.dataset.clipboardTarget = '#copiee';

  const clipboard = new ClipboardJS('.copier');
  clipboard.on('success', (e) => {
    console.info('Action:', e.action);
    console.info('Text:', e.text);
    e.clearSelection();
    result = true;
  });
  clipboard.on('error', (e) => {
    console.error('Action:', e.action);
    result = false;
  });

  document.body.appendChild(textarea);
  document.body.appendChild(button);
  button.click();
  document.body.removeChild(textarea);
  document.body.removeChild(button);
  clipboard.destroy();

  return result;
}

// Not support hex shortcut
export function convertHexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
}

export function detectMobileDevice(agent: string) {
  const mobileRegex = [
    /Android/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return mobileRegex.some((mobile) => agent.match(mobile));
}

export function detectAppleMobileDevice(agent: string) {
  const mobileRegex = [/iPhone/i, /iPad/i, /iPod/i];

  return mobileRegex.some((mobile) => agent.match(mobile));
}

export function resolveDomain(locationHost: string) {
  return locationHost
    .split('.')
    .reduce((a, b, idx) => {
      if (idx === 1) return b;
      return `${a}.${b}`;
    })
    .replace(':3000', '');
}

resolveDomain(document.location.host);

/* eslint-disable indent */
import { Address, LibConfig } from 'buk-viewer';
import { t } from 'i18next';
import ShareInfo from '../../models/ShareInfo';
import { convertHexToRGB, resolveDomain } from '../../utils';
import ModalTrigger from '../../components/commons/ModalTrigger';

export const highlightColors: { [index: string]: string } = {
  'bukh-color-yellow': '#FFEC8A',
  'bukh-color-green': '#BEE64C',
  'bukh-color-blue': '#88D5FF',
  'bukh-color-red': '#FF9E9E',
} as const;

export const CONTEXT_MENU_ACTIONS = ['delete', 'listen', 'search', 'share'];

export const ALLOWED_TEXT_SELECTION_LENGTH = 1000;

const initialSettings = {
  theme: 'light',
  fontFace: 'default',
  fontSize: 100,
  lineHeight: 100,
  pagingMode: 'page',
  pageAnimation: true,
  multiColumn: false,
  clickToPlayMediaOverlay: false,
};

export default function setConfig(hasBook = false): LibConfig {
  return {
    contentsBaseURL: `${process.env.REACT_APP_CONTENTS_BASE_URL}${resolveDomain(
      document.location.host,
    )}`,
    highlightStyles: [
      {
        styleClass: 'bukh-color-yellow',
        style: `background: ${convertHexToRGB(
          highlightColors['bukh-color-yellow'],
          0.4,
        )};`,
      },
      {
        styleClass: 'bukh-color-green',
        style: `background: ${convertHexToRGB(
          highlightColors['bukh-color-green'],
          0.4,
        )};`,
      },
      {
        styleClass: 'bukh-color-blue',
        style: `background: ${convertHexToRGB(
          highlightColors['bukh-color-blue'],
          0.4,
        )};`,
      },
      {
        styleClass: 'bukh-color-red',
        style: `background: ${convertHexToRGB(
          highlightColors['bukh-color-red'],
          0.4,
        )};`,
      },
      {
        styleClass: 'bukh-color-default',
        style: `background: #007aff33`,
      },
    ],
    fonts: {
      en: {
        fontFaces: [
          'https://tapas-meta-contents.s3.ap-northeast-2.amazonaws.com/fonts/charter.css',
          'https://tapas-meta-contents.s3.ap-northeast-2.amazonaws.com/fonts/opensans.css',
          'https://tapas-meta-contents.s3.ap-northeast-2.amazonaws.com/fonts/roboto.css',
        ],
        fontFamilies: ['Charter', 'Open-Sans', 'Roboto'],
      },
    },
    updateHTTPRequest: () => ({
      withCredentials: process.env.REACT_APP_PROTOCOL !== 'http',
    }),
    canChangeAddress: hasBook
      ? undefined
      : (newAddress: Address) => {
          const data = ShareInfo.load();

          if (!data) return false;

          const sharedAnchor = `${data.anchor.start}-${data.anchor.end}`;

          if (sharedAnchor === newAddress.anchor) {
            return true;
          }

          ModalTrigger.info(
            t('epub_subscribe_title'),
            t('epub_subscribe_subtitle'),
            t('epub_subscribe_button_ok'),
          );
          return false;
        },
    initialSettings: localStorage.getItem('bukv.settings')
      ? JSON.parse(localStorage.getItem('bukv.settings')!)
      : initialSettings,
  };
}

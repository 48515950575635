import React from 'react';
import { Icon } from '@ipf-dev/web-spindle-design-system';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { BookmarkState, ViewerState } from '../../../../state';
import { MenuItem } from './Menu';
import responsive from '../../../../styles/responsive';

const Wrapper = styled.div`
  margin-left: min(5.6rem, 3.5vw);
  ${responsive.conditionForOnlyOneSideView} {
    margin-left: 3.2rem;
  }
`;

function BookmarkButton() {
  const theme = useTheme();
  const { t } = useTranslation();

  const isBookmarkActive = useRecoilValue(BookmarkState);
  const viewer = useRecoilValue(ViewerState);

  const handleBookmarkButton = () => {
    viewer?.toggleBookmark();
  };

  return (
    <Wrapper>
      <MenuItem
        layout="desktop"
        name={t('epub_tool_bar_bookmark')}
        onClick={handleBookmarkButton}
      >
        {!isBookmarkActive && (
          <Icon.Header
            styleType="bookmark"
            color={theme.colorTokens.icon.primary}
            platform="mobile"
          />
        )}
        {isBookmarkActive && (
          <Icon.Header styleType="bookmark" platform="mobile" selected />
        )}
      </MenuItem>
    </Wrapper>
  );
}

export default BookmarkButton;

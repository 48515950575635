import styled, { css } from 'styled-components';
import * as typography from '@tapas/typography';

export const Wrapper = styled.div`
  overflow-y: auto;
`;

export const MetaBox = styled.div`
  display: flex;
  padding: 3.2rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.8rem;
  background: ${({ theme }) => theme.colorTokens.hierarchy.h5};
`;

export const BookCover = styled.img`
  width: 9.6rem;
  height: 14.6rem;
  margin-right: 1.6rem;
  border-radius: 4px;
  object-fit: cover;
`;

export const BookInfoBox = styled.div``;

export const BookTitleLabel = styled.h3`
  display: block;
  margin-bottom: 0.4rem;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 700)}
`;

export const BookAuthorLabel = styled.p`
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${({ theme }) => typography.body(theme.fonts.default, 1.4, 300)}
`;

export const TocBox = styled.div`
  height: 100%;
`;

export const activeContentItemStyle = css`
  color: ${({ theme }) => theme.colorTokens.accent.default};
  background-color: ${({ theme }) => theme.colorTokens.hierarchy.h6};
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 700)}
`;

export const ContentItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 500)}
  ${({ isActive }) => isActive && activeContentItemStyle}
`;

export const ItemLabel = styled.span`
  display: inline-block;
  padding: 1.6rem 3.2rem;
`;

export const ItemDivider = styled.div`
  width: 90%;
  height: 0.1rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colorTokens.hierarchy.h5};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { highlightColors } from '../../../../lib/buk-viewer/viewer.config';
import {
  Wrapper,
  SelectAllColorCircle,
  ColorCircle,
} from './ColorSelectBox.style';

interface ColorSelectBoxProps {
  styleType: 'filter' | 'palette';
  selectedColor: string;
  onSelect: (styleClass: string) => void;
}

function ColorSelectBox({
  styleType,
  selectedColor,
  onSelect,
}: ColorSelectBoxProps) {
  const { t } = useTranslation();

  return (
    <Wrapper styleType={styleType}>
      {styleType === 'filter' && (
        <SelectAllColorCircle
          isSelected={selectedColor === ''}
          onClick={() => onSelect('')}
        >
          {t('epub_record_tab_highlight_button_title_all')}
        </SelectAllColorCircle>
      )}
      {Object.entries(highlightColors).map(([styleClass, style], idx) => (
        <ColorCircle
          styleType={styleType}
          key={`highlight-${styleClass + idx}`}
          isSelected={styleClass === selectedColor}
          styleClass={styleClass}
          onClick={() => onSelect(styleClass)}
        />
      ))}
    </Wrapper>
  );
}

export default ColorSelectBox;

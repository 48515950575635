import styled from 'styled-components';
import { colorScales } from '@ipf-dev/web-spindle-design-system';
import { body } from '@tapas/typography';

export const Wrapper = styled.div<{ timeout: number }>`
  position: fixed;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
  z-index: 9001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
  padding: 2.4rem 0;
  background: ${({ theme }) => theme.epubColorTokens.toastOverlay.background};
  backdrop-filter: blur(20px);
  border-radius: 1rem;
  pointer-events: none;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 100%;
    }
    100% {
      opacity: 0;
    }
  }

  animation: fadeIn 300ms, fadeOut 300ms ${({ timeout }) => timeout}ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
`;

export const IconBox = styled.div`
  padding-bottom: 1.6rem;
`;

export const Label = styled.span`
  display: block;
  height: 100%;
  color: ${colorScales.white};
  user-select: none;
  ${({ theme }) => body(theme.fonts.default, 1.4, 700)}
`;

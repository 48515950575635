import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Input, Icon, ModalDialog } from '@ipf-dev/web-spindle-design-system';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { tabletQuery } from '../../../styles/responsive';
import { SelectedTextState } from '../../../state';
import Tab from '../../commons/Tab';
import Dictionary from './Dictionary';
import Sentence from './SearchResultList/SearchResultList';
import { Form, SubmitButton } from './Search.style';

interface SearchProps {
  isSearchOpen: boolean;
  onClose: Function;
}

function Search({ isSearchOpen, onClose }: SearchProps) {
  const selectedText = useRecoilValue(SelectedTextState);
  const [text, setText] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [isQuerySentence, setIsQuerySentence] = useState<boolean>(false);
  const isTablet = useMediaQuery({ query: tabletQuery });
  const theme = useTheme();

  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setQuery(text);
  };

  useEffect(() => {
    if (!isSearchOpen) return;

    if (selectedText.text) {
      setText(selectedText.text);
      setQuery(selectedText.text);
    }
  }, [isSearchOpen]);

  useEffect(() => {
    text.split(' ').length === 1 && setIsQuerySentence(false);
    text.split(' ').length !== 1 && setIsQuerySentence(true);
  }, [text]);

  return (
    <ModalDialog
      styleType="header"
      headerOptions={{
        title: t('epub_search_title'),
        right: {
          label: (
            <Icon.XShape
              styleType="close"
              color={theme.colorTokens.hierarchy.h1}
            />
          ),
          onClick: () => onClose(),
        },
      }}
      visible={isSearchOpen}
      onClose={() => {
        onClose();
        setText('');
        setQuery('');
      }}
      style={{
        height: '64rem',
        width: `${isTablet ? 'calc(100% - 12.8rem)' : 'calc(100% - 6.4rem)'}`,
        maxWidth: '64rem',
        zIndex: 8000,
      }}
    >
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Input.Input
          id="search-input"
          label={t('epub_search_input_placeholder')}
          validState="valid"
          styleType="text"
          value={text}
          onChange={(e: any) => setText(e.target.value)}
          onResetIconClick={() => setText('')}
          placeholder=""
          style={{ marginRight: '0.8rem' }}
        />
        <SubmitButton>{t('epub_search_button_search')}</SubmitButton>
      </Form>
      <Tab
        tabItems={[
          {
            id: 'tab1',
            title: t('epub_search_tab_extract'),
            contents: (
              <Sentence
                value={text}
                source="original"
                query={query}
                onClose={onClose}
                from="search"
              />
            ),
          },
          {
            id: 'tab2',
            title: t('epub_search_tab_dictionary'),
            contents: <Dictionary word={query} from="search" />,
          },
          {
            id: 'tab3',
            title: t('epub_search_tab_example'),
            contents: (
              <Sentence
                value={text}
                source="example"
                query={query}
                onClose={onClose}
                from="search"
              />
            ),
          },
        ]}
        styleType="fullwidth"
        options={{
          tabNavItemStyle: {
            color: `${theme.colorTokens.hierarchy.h1}`,
            margin: 0,
            fontSize: '1.4rem',
          },
        }}
      />
    </ModalDialog>
  );
}

export default Search;

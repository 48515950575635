import i18n from 'i18next';

type Language = 'ko' | 'en';

const devtools = {
  changeLang: (lang: Language) => {
    i18n.changeLanguage(lang);
  },
};

function init() {
  window.devtools = devtools;
}

export default init;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ViewerState, MediaInfoState } from '../../../../state';
import * as Icon from '../../../../assets/icons';
import { renderRateNumber } from '../../../../helpers';
import { useHover } from '../../../../hooks';
import {
  BoxButton,
  BoxButtonBackground,
  CircleButton,
  ControlBox,
  LeftBoxButtonLabel,
  RightBoxButtonLabel,
  Wrapper,
} from './MediaController.style';
import PlaybackRateController from '../PlaybackRateController';
import PageNavigator from '../../PageNavigator';

interface MediaControllerProps {
  onClose: Function;
}

function MediaController({ onClose }: MediaControllerProps) {
  const viewer = useRecoilValue(ViewerState);
  const { isPlaying, playbackRate } = useRecoilValue(MediaInfoState);

  const [
    isPlaybackRateControllerOpen,
    setPlaybackRateControllerOpen,
  ] = useState<boolean>(false);

  const [lightButtonRef, isRightButtonHovered] = useHover();

  const { t } = useTranslation();

  const handlePauseAndClose = () => {
    viewer?.pauseMediaOverlay();
    onClose();
  };

  const handlePlayPause = () => {
    if (isPlaying) viewer?.pauseMediaOverlay();
    if (!isPlaying) viewer?.playMediaOverlay();
  };

  const handlePrevPlay = () => {
    viewer?.playMediaOverlay(-1);
  };

  const handleNextPlay = () => {
    viewer?.playMediaOverlay(1);
  };

  const handleOpenPlaybackRateView = () => {
    setPlaybackRateControllerOpen(!isPlaybackRateControllerOpen);
  };

  return (
    <Wrapper data-cy="media-controller">
      <ControlBox>
        <BoxButton onClick={handlePauseAndClose}>
          <BoxButtonBackground />
          <LeftBoxButtonLabel>
            {t('epub_media_overlay_quit')}
          </LeftBoxButtonLabel>
        </BoxButton>
        <CircleButton onClick={handlePrevPlay}>
          <Icon.PrevPlay />
        </CircleButton>
        <CircleButton onClick={handlePlayPause}>
          {!isPlaying && (
            <Icon.PlayLarge width="64" height="64" viewBox="0 0 64 64" />
          )}
          {isPlaying && <Icon.PauseLarge width="64" height="64" />}
        </CircleButton>
        <CircleButton onClick={handleNextPlay}>
          <Icon.NextPlay />
        </CircleButton>
        <BoxButton
          ref={lightButtonRef as any}
          onClick={handleOpenPlaybackRateView}
        >
          <BoxButtonBackground />
          <RightBoxButtonLabel>
            {renderRateNumber(playbackRate)}
            <Icon.ArrowUp
              style={{
                transform: isRightButtonHovered
                  ? `translate(-0.3rem, 0)`
                  : `translate(-0.3rem, 0.2rem)`,
              }}
            />
          </RightBoxButtonLabel>
        </BoxButton>
      </ControlBox>
      <PageNavigator visible={true} styleType="lean" />
      {isPlaybackRateControllerOpen && (
        <PlaybackRateController
          rate={playbackRate}
          step={0.25}
          onClose={() => setPlaybackRateControllerOpen(false)}
        />
      )}
    </Wrapper>
  );
}

export default MediaController;

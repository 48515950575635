import React from 'react';
import styled from 'styled-components';
import { useViewerTheme } from './hooks';
import PageControl from '../PageControl/PageControl';

function Viewer() {
  useViewerTheme();

  return (
    <ViewerWrapper>
      <buk-viewer />
      <PageControl />
    </ViewerWrapper>
  );
}

export default Viewer;

const ViewerWrapper = styled.div`
  width: 100%;
  height: 100%;

  buk-viewer {
    width: 100%;
    height: 100%;
  }
`;

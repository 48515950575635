import { colorScales } from '@ipf-dev/web-spindle-design-system';
import { addAlpha } from '../helpers';

export default {
  contextMenu: {
    default: colorScales.gray80,
    pressed: colorScales.gray90,
    background: colorScales.gray90,
  },
  playButton: {
    default: colorScales.gray80,
    hover: colorScales.gray70,
    pressed: colorScales.black,
  },
  mediaController: {
    background: colorScales.gray90,
    icon: {
      default: colorScales.white,
    },
    button: {
      default: colorScales.gray50,
      hover: colorScales.gray60,
      pressed: colorScales.gray70,
    },
  },
  speedControl: {
    background: colorScales.gray80,
    divider: colorScales.gray40,
  },
  progressBar: {
    background: colorScales.gray60,
    active: colorScales.gray30,
    text: colorScales.gray50,
  },
  toastOverlay: {
    background: addAlpha(colorScales.gray80, 0.8),
  },
};

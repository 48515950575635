import { TOCItem } from 'buk-viewer';
import invariant from 'tiny-invariant';

export const parseBukURL = (url: string) => {
  const regex = new RegExp(/\/?(@[a-z0-9]+)\/?([a-z0-9]+)?\/?([a-z-0-9]+)?/);
  const result = url.match(regex);

  if (!result)
    return {
      fullURL: url,
      bid: '',
      iid: null,
      pageOrAnchor: null,
    };

  const [fullURL, bid, iid, pageOrAnchor] = result;

  return {
    fullURL,
    bid,
    iid,
    pageOrAnchor,
  };
};

export const getChapterNameByURL = (url: string, toc: TOCItem[]) => {
  const { iid } = parseBukURL(url);

  try {
    const [itemInfo] = toc.filter((item) => item.address === iid);
    return itemInfo.title;
  } catch (e) {
    console.error(e);
    return 'Unknown Chapter';
  }
};

export interface AnnotationInfo {
  url: string;
  type?: 'highlight' | 'bookmark';
  styleClass?:
    | 'bukh-color-yellow'
    | 'bukh-color-green'
    | 'bukh-color-blue'
    | 'bukh-color-red';
}

export function makeShareURL({ url, type, styleClass }: AnnotationInfo) {
  console.debug(url, type, styleClass);

  const makeTypeQueryString = () => {
    if (type) {
      return `?type=${type}`;
    }
    return '';
  };

  const makeColorQueryString = () => {
    if (styleClass) {
      return `&color=${styleClass.replace('bukh-color-', '')}`;
    }
    return '';
  };

  // FIXME
  return `https://stage.readingn.com/epub/${
    window.cmsid
  }${url}${makeTypeQueryString()}${makeColorQueryString()}`;
}

export function parseShareURLQueryString() {
  const params = new URLSearchParams(document.location.search);
  const mode = params.get('mode') ?? '';
  const type = params.get('type');
  const color = params.get('color');

  invariant(!!mode, 'parsing ShareURL Error - mode is null');

  return {
    mode,
    type,
    color,
  };
}

import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface ModalPortalProps {
  rootId: string;
  children: ReactNode;
}

const Portal = ({ rootId, children }: ModalPortalProps) => {
  const element = document.getElementById(rootId);

  if (!element) {
    console.log(`Cannot find ${rootId}`);
    return null;
  }

  return ReactDOM.createPortal(children, element);
};

export default Portal;

/* eslint-disable indent */
import { Theme } from 'buk-viewer';
import { DefaultTheme } from 'styled-components';
import { makeCustomScrollBarStyle } from '../../../../commons/CustomScrollBar';

const TAG = '/*custom-theme*/';

const COMMON_STYLE = (theme: DefaultTheme) => `
  ${TAG}
  ${makeCustomScrollBarStyle(
    theme.colorTokens.hierarchy.h4,
    theme.colorTokens.background.primary,
  )}

  *::selection {
    background-color: rgba(0, 122, 255, 0.2);
  }
`;

const makeLightStyle = (theme: DefaultTheme) => `
  ${COMMON_STYLE(theme)}

  html {
    background-color: ${theme.custom.backgroundLighten} !important;
    color: ${theme.custom.textColorLighten} !important;
  }

  *.-epub-media-overlay-active {
    background-color: ${theme.custom.mediaOverlay} !important;
    border-radius: 0.4rem;
  }

  *.-epub-media-overlay-active > .dropcaps {
    background-color: ${theme.custom.mediaOverlay} !important;
    border-radius: 0.4rem;
  }
`;

const makeDarkStyle = (theme: DefaultTheme) => `
  ${COMMON_STYLE(theme)}

  html {
    background-color: ${theme.custom.backgroundDarken} !important;
    color: ${theme.custom.textColorDarken} !important;
  }

  html[data-bukv-theme=dark]:not(.buk-type-wikicover):not([data-bukv-layout=fixed]) * {
    color: ${theme.custom.textColorDarken} !important;
  }

  html[data-bukv-theme=dark]:not(.buk-type-wikicover):not([data-bukv-layout=fixed]) *:not(x):not(.bukv_mo-active).-epub-media-overlay-active {
    background-color: ${theme.custom.mediaOverlay} !important;
    border-radius: 0.4rem;
  }

  html[data-bukv-theme=dark]:not(.buk-type-wikicover):not([data-bukv-layout=fixed]) *:not(x):not(.bukv_mo-active).-epub-media-overlay-active > .dropcaps {
    background-color: ${theme.custom.mediaOverlay} !important;
    border-radius: 0.4rem;
  }

  html[data-bukv-layout=column][data-bukv-multi-column=true]:not([data-bukv-is-cover=true]) body  {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' height='2' width='1'%3E%3Cline x1='0' y1='0' x2='0' y2='1' style='stroke:${encodeURIComponent(
      theme.custom.middleDivider,
    )};stroke-width:2' /%3E%3C/svg%3E") !important;
  }
`;

export const bukViewerPadding = {
  top: '8rem',
  bottom: '8rem',
};

export default function changeBukViewerTheme(
  mode: Theme,
  theme: DefaultTheme,
  padding: typeof bukViewerPadding,
) {
  document.querySelectorAll('bukv-frame').forEach((frame) => {
    const bukvFrame = frame as HTMLElement;
    bukvFrame.style.backgroundColor =
      mode === 'light'
        ? theme.custom.backgroundLighten
        : theme.custom.backgroundDarken;
    bukvFrame.style.paddingTop = padding.top;
    bukvFrame.style.paddingBottom = padding.bottom;
  });

  document.querySelectorAll('iframe').forEach((iframe) => {
    if (!iframe.contentDocument) return;

    const lastStyle = iframe.contentDocument.head.lastChild as HTMLElement;
    const hasCustomStyle = lastStyle!.innerText.indexOf(TAG) > -1;
    if (hasCustomStyle) {
      lastStyle.remove();
    }

    const themeStyle = document.createElement('style');
    iframe.contentDocument.head.appendChild(themeStyle);
    const css = mode === 'light' ? makeLightStyle(theme) : makeDarkStyle(theme);
    themeStyle.appendChild(document.createTextNode(css));
  });
}

import { atom, selector } from 'recoil';

export interface Annotations {
  _id: string;
  type: 'bookmark' | 'highlight';
  url: string;
  text: string;
  styleClass: any;
  createdAt: number;
  printPage?: number;
}

export const AnnotationsState = atom<Annotations[]>({
  key: 'Annotations',
  default: [],
});

export const BookmarksState = selector({
  key: 'Bookmarks',
  get: ({ get }) =>
    get(AnnotationsState).filter(({ type }) => type === 'bookmark'),
});

export const HighlightsState = selector({
  key: 'Highlights',
  get: ({ get }) =>
    get(AnnotationsState).filter(({ type }) => type === 'highlight'),
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ViewerState } from '../../../../state';
import * as Icon from '../../../../assets/icons';
import { Wrapper, Button, Label } from './PayButton.style';

interface PlayButtonProps {
  onClose: Function;
}

function PlayButton({ onClose }: PlayButtonProps) {
  const viewer = useRecoilValue(ViewerState);
  const { t } = useTranslation();

  const handlePlayButton = () => {
    viewer?.playMediaOverlay();
    onClose();
  };

  return (
    <Wrapper>
      <Button onClick={handlePlayButton}>
        <Icon.PlaySmall />
        <Label>{t('epub_tool_bar_read_aloud')}</Label>
      </Button>
    </Wrapper>
  );
}

export default PlayButton;

import { v4 as uuidv4 } from 'uuid';
import localforage from '../lib/localforage';
/*
  Words 클래스를 통해 외부 저장소의 CRUD 작업. onChange를 통해 앱의 words 목록 갱신
*/
class Words {
  bid: string;
  onChange: Function;

  constructor(bid: string, onChange: Function) {
    this.bid = bid;
    this.onChange = onChange;
  }

  create(word: string) {
    const id = uuidv4();

    const dataField = {
      _id: id,
      type: 'word',
      text: word,
      createdAt: new Date().getTime(),
      updateAt: null,
      url: null,
      styleClass: null,
      remembered: false,
    };

    localforage.setItem(this.getItemKey(id), JSON.stringify(dataField));

    this.onChange();

    return id;
  }

  async update(wordData: any) {
    const _id = wordData._id;

    const newWordData = {
      ...wordData,
      updatedAt: new Date().getTime(),
    };

    try {
      await localforage.setItem(
        this.getItemKey(_id),
        JSON.stringify(newWordData),
      );
      this.onChange();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  delete(_ids: string[]) {
    try {
      _ids.forEach((_id) => {
        localforage.removeItem(this.getItemKey(_id));
      });

      this.onChange();
      return _ids;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async get(_id: string) {
    const data = await localforage.getItem(this.getItemKey(_id));

    if (!data) return {};

    return JSON.parse(data as string);
  }

  async getAll() {
    const length = await localforage.length();

    const KeyNamePromises = Array.from({ length }).map(async (value, index) => {
      const keyName = await localforage.key(index);
      return keyName;
    });
    const keyNames = await Promise.all(KeyNamePromises);

    const resultPromises = keyNames
      .filter((key) => {
        const keyRegex = new RegExp(`^words.${this.bid}.[a-z0-9-]+$`);
        const flag = keyRegex.test(key);
        return flag;
      })
      .map(async (key) => ({
        ...JSON.parse((await localforage.getItem(key)) ?? ''),
        _id: key.split('.')[2],
      }));
    const result = await Promise.all(resultPromises);
    return result;
  }

  getItemKey(id: string) {
    return `words.${this.bid}.${id}`;
  }
}

export default Words;

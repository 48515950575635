import { Toast, buildSpindleTheme } from '@ipf-dev/web-spindle-design-system';

const ThemedToast = () => {
  Toast.initialize(
    buildSpindleTheme({ colorTokens: { mode: window.theme as any } }),
  );
  return Toast;
};

export default ThemedToast;

import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useRecoilValue } from 'recoil';
import { BookMetaState } from '../../../../../state';
import Words from '../../../../../models/Words';

export default function useWordsState(): [
  any[],
  Dispatch<SetStateAction<any[]>>,
  ] {
  const bookMeta = useRecoilValue(BookMetaState);
  const [words, setWords] = useState<any[]>([]);

  const getAllWords = useCallback(async () => {
    const allWords = await new Words(bookMeta!.bid, () => {}).getAll();
    setWords(allWords);
  }, [bookMeta]);

  useEffect(() => {
    getAllWords();
  }, [getAllWords]);

  return [words, setWords];
}

import { useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { PageInfoState } from '../../../../state';
import { useThrottle } from '../../../../hooks';

function usePageControl() {
  const onMousemove = useCallback((e: MouseEvent) => {
    const pageControlLeft = document.getElementById('page-control-left');
    const pageControlRight = document.getElementById('page-control-right');
    if (!pageControlLeft || !pageControlRight) return;

    const { width } = window.visualViewport;
    const { clientX } = e;
    const ratio = clientX / width;
    const threshold = 0.2;

    if (ratio <= threshold) {
      pageControlLeft.style.display = 'block';
    } else if (ratio >= 1 - threshold) {
      pageControlRight.style.display = 'block';
    } else {
      pageControlLeft.style.display = 'none';
      pageControlRight.style.display = 'none';
    }
  }, []);

  const pageInfo = useRecoilValue(PageInfoState);

  const throttleOnMousemove = useThrottle(onMousemove, 100);

  useEffect(() => {
    document.querySelectorAll('iframe').forEach((iframe) => {
      if (!iframe.contentDocument) return;
      iframe.contentDocument.addEventListener('mousemove', throttleOnMousemove);
    });
    return () => {
      document.querySelectorAll('iframe').forEach((iframe) => {
        if (!iframe.contentDocument) return;
        iframe.contentDocument.removeEventListener(
          'mousemove',
          throttleOnMousemove,
        );
      });
    };
  }, [pageInfo, throttleOnMousemove]);
}

export default usePageControl;

import styled, { css } from 'styled-components';
import responsive from '../../../styles/responsive';

const leftStyle = css`
  left: 1.6rem;
`;

const rightStyle = css`
  right: 1.6rem;
`;

export const ArrowButton = styled.button<{
  isMultiColumn: boolean;
  direction: string;
}>`
  display: none;
  position: absolute;
  top: 50%;

  :hover {
    svg {
      fill: ${({ theme }) => theme.colorTokens.hierarchy.h3};
    }
  }

  ${responsive.conditionForTablet} {
    display: none;
  }

  ${({ direction }) => (direction === 'left' ? leftStyle : rightStyle)}
`;

import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  PageInfoState,
  HasPrintPageInfoState,
  ViewerState,
  ItemInfoState,
} from '../../../state';
import PurePageNavigator from './PageNavigator';
import { PageNavigatorType } from './types';

interface ContainerProps {
  visible: boolean;
  styleType: PageNavigatorType;
}

function PageNavigator({ visible, styleType }: ContainerProps) {
  const itemInfo = useRecoilValue(ItemInfoState);
  const pageInfo = useRecoilValue(PageInfoState);
  const hasPrintPageInfo = useRecoilValue(HasPrintPageInfoState);
  const viewer = useRecoilValue(ViewerState);

  return (
    <PurePageNavigator
      visible={visible}
      styleType={styleType}
      itemInfo={itemInfo}
      pageInfo={pageInfo}
      hasPrintPageInfo={hasPrintPageInfo}
      viewer={viewer}
    />
  );
}

export default PageNavigator;

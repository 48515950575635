import styled from 'styled-components';
import { body } from '@tapas/typography';

export const Wrapper = styled.div`
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colorTokens.hierarchy.h4};
`;

export const Sentence = styled.span<{ canClick: boolean }>`
  display: inline-block;
  margin-bottom: 0.8rem;
  cursor: ${({ canClick }) => (canClick ? 'pointer' : 'text')};
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${({ theme }) => body(theme.fonts.default, 1.6, 500)}
`;

export const SentenceInfo = styled.span`
  display: block;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h2};
  ${({ theme }) => body(theme.fonts.default, 1.4, 500)}
`;

export const HighlightedWord = styled.span`
  color: ${({ theme }) => theme.colorTokens.accent.default};
  font-weight: 700;
`;

import { t } from 'i18next';
import { PagingMode } from 'buk-viewer';
import { getViewer } from './index';
import ViewInfo from '../components/commons/ToastOverlay';

export function changeToScrollView() {
  getViewer().changePagingMode('scroll' as PagingMode);
  ViewInfo.scrollView(t('epub_viewmode_toast_scroll'));
}

export function changeToOnePageView() {
  getViewer().changePagingMode('page' as PagingMode);
  getViewer().setMultiColumn(false);
  ViewInfo.oneSideView(t('epub_viewmode_toast_one_page'));
}

export function changeToTwoPageView() {
  getViewer().changePagingMode('page' as PagingMode);
  getViewer().setMultiColumn(true);
  ViewInfo.bothSideView(t('epub_viewmode_toast_two_page'));
}

export const tabletQuery = '(max-width: 768px)';
export const mobileQuery = '(max-width: 400px)';
export const OnlyOneSideViewQuery = '(max-width: 1024px)';
export const mobileRightSideMaxWidth = '(max-width: 1340px)';

export default {
  conditionForTablet: `@media screen and ${tabletQuery}`,
  conditionForMobile: `@media screen and ${mobileQuery}`,
  conditionForOnlyOneSideView: `@media screen and ${OnlyOneSideViewQuery}`,
  conditionForMobileRightSide: `@media screen and ${mobileRightSideMaxWidth}`,
};

import styled from 'styled-components';
import * as typography from '@tapas/typography';
import { addAlpha } from '../../../../helpers';

export const Wrapper = styled.div`
  position: relative;
  width: 36rem;
  margin: 0 auto;
  margin-bottom: 0.8rem;
  padding: 1.6rem 1.6rem 0.7rem 1.6rem;
  background-color: ${({ theme }) =>
    addAlpha(theme.epubColorTokens.mediaController.background, 0.8)};
  backdrop-filter: blur(4px);
  border-radius: 2.4rem;
`;

export const ControlBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
`;

export const Button = styled.button`
  margin-left: 2rem;

  &:first-child {
    margin-left: 0;
  }
`;

export const BoxButton = styled(Button)`
  position: relative;
  width: 6.4rem;
  height: 4rem;
`;

export const BoxButtonBackground = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  background: ${({ theme }) =>
    theme.epubColorTokens.mediaController.button.default};
`;

export const BoxButtonLabel = styled.span`
  position: absolute;
  top: 0.9rem;
  left: 0;
  width: 100%;
  display: inline;
  color: ${({ theme }) => theme.colorTokens.accent.on};
`;

export const LeftBoxButtonLabel = styled(BoxButtonLabel)`
  transform: translateY(0.1rem);
  ${({ theme }) => typography.caption(theme.fonts.default, 500)}
`;

export const RightBoxButtonLabel = styled(BoxButtonLabel)`
  padding-left: 1rem;
  ${typography.body('noto-sans', 1.4, 500)}
`;

export const CircleButton = styled(Button)``;

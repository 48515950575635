import styled from 'styled-components';
import { body, caption } from '@tapas/typography';
import { highlightColors } from '../../../../lib/buk-viewer/viewer.config';

export const Container = styled.div`
  display: flex;
  padding-top: 1.6rem;
`;

export const SelectBox = styled.div`
  display: inline-block;
  margin-right: 2rem;
  padding-top: 1.6rem;
`;

export const CheckboxIconStyle = styled.div`
  path {
    fill: ${({ theme }) => theme.colorTokens.accent.on};
  }
`;

export const Wrapper = styled.div<{ isEditing: boolean }>`
  position: relative;
  width: 100%;
  display: inline-block;
  cursor: ${({ isEditing }) => (isEditing ? 'pointer' : 'default')};
`;

export const MainBox = styled.div`
  max-width: 57.6rem;
  display: flex;
  padding: 1.6rem 0;
  padding-right: 1.6rem;
  border-radius: 1.6rem;
  background: ${({ theme }) => theme.colorTokens.hierarchy.h6};
`;

export const IconBox = styled.div`
  display: inline-block;
  padding: 0 1.6rem;
`;

export const MoreIconBox = styled.div`
  position: absolute;
  display: inline-block;
  bottom: 0.8rem;
  right: 1.6rem;
`;

export const InfoBox = styled.div`
  width: 100%;
  display: inline-block;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h2};
`;

export const TextLabel = styled.span<{ isEditing: boolean }>`
  display: inline-block;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  cursor: pointer;
  ${({ theme }) => body(theme.fonts.default, 1.6, 500)}
  ${({ isEditing }) => isEditing && 'pointer-events: none;'}
`;

export const ChapterLabel = styled.span`
  display: block;
  ${({ theme }) => body(theme.fonts.default, 1.4, 500)}
`;

export const PageLabel = styled.span`
  display: inline-block;
  margin-top: 1.6rem;
  ${({ theme }) => caption(theme.fonts.default, 500)}
`;

export const DateLabel = styled.span`
  display: inline-block;
  margin-top: 1.6rem;
  ${body('noto-sans', 1.4, 300)};
`;

export const Divider = styled.div`
  width: 0.1rem;
  height: 1.3rem;
  display: inline-block;
  margin: 0 0.8rem;
  transform: translateY(20%);
  border-right: 0.1rem solid ${({ theme }) => theme.colorTokens.hierarchy.h4};
`;

export const ColorCircle = styled.div<{ styleClass: string }>`
  width: 1.6rem;
  height: 1.6rem;
  transform: translateY(0.4rem);
  border-radius: 50%;
  background-color: ${({ styleClass }) =>
    highlightColors[styleClass ?? 'bukh-color-yellow']};
`;

import { atom } from 'recoil';

interface SelectedText {
  text: string;
  url: string;
}

export default atom<SelectedText>({
  key: 'SelectedTextState',
  default: {
    text: '',
    url: '',
  },
});

import styled, { css } from 'styled-components';
import { body } from '@tapas/typography';
import { highlightColors } from '../../../../lib/buk-viewer/viewer.config';

const paletteWrapperStyle = css`
  margin: 1.6rem 0;
`;

export const Wrapper = styled.div<{ styleType: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.8rem 0;

  ${({ styleType }) => styleType === 'palette' && paletteWrapperStyle}
`;

const selectedStyle = css`
  border: 0.2rem solid ${({ theme }) => theme.colorTokens.accent.default};
  color: ${({ theme }) => theme.colorTokens.accent.default};
`;

const paletteCircleStyle = css`
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 1.6rem;
`;

export const ColorCircle = styled.button<{
  isSelected: boolean;
  styleClass?: string;
  styleType?: string;
}>`
  width: 4.8rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  border-radius: 10rem;
  background-color: ${({ styleClass }) =>
    styleClass && highlightColors[styleClass ?? 'bukh-color-yellow']};

  ${({ isSelected }) => isSelected && selectedStyle}
  ${({ styleType }) => styleType === 'palette' && paletteCircleStyle}

  &:first-child {
    margin-left: 0;
  }
`;

export const SelectAllColorCircle = styled(ColorCircle)`
  width: 5.6rem;

  ${({ theme }) => body(theme.fonts.default, 1.4, 500)};
  ${({ isSelected }) => !isSelected && 'border: 0.2rem solid gray'};
  ${({ isSelected }) => !isSelected && 'color: gray'};
`;

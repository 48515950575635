import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@ipf-dev/web-spindle-design-system';
import { useRecoilValue } from 'recoil';
import {
  Annotations,
  BookmarksState,
  BookMetaState,
  BookTocState,
  HighlightsState,
} from '../../../../state';
import { formatUnixTime, getChapterNameByURL } from '../../../../helpers';
import Words from '../../../../models/Words';
import {
  Wrapper,
  WordsWrapper,
  NoneDataWrapper,
  NoneDataInfoLabel,
} from './RecordsList.style';
import { RecordsListProps } from './types';
import { useWordsState } from './hooks';
import { AnnotationItem, WordItem } from '../ListItem';
import ColorSelectBox from '../ColorSelectBox';
import RecordDeleteModal from '../RecordDeleteModal';

function RecordsList({
  recordsType,
  onClose,
  isEditing,
  selections,
  onSelect,
  data,
  onSetData,
  selectedData,
  onResetSelections,
  onSetIsEditing,
}: RecordsListProps) {
  const { t } = useTranslation();
  const toc = useRecoilValue(BookTocState);
  const bookMeta = useRecoilValue(BookMetaState);

  const bookmarks = useRecoilValue(BookmarksState);
  const highlights = useRecoilValue(HighlightsState);
  const [words, setWords] = useWordsState();
  const [colorFilter, setColorFilter] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useLayoutEffect(() => {
    let HEAD: Annotations[] = [];

    switch (recordsType) {
      case 'bookmark':
        HEAD = bookmarks;
        break;
      case 'highlight':
        HEAD = highlights.filter(({ styleClass }) => {
          if (colorFilter === '') return true;
          return styleClass === colorFilter;
        });
        break;
      case 'word':
        HEAD = words;
        break;
      default:
        throw new Error('Not Valid RecordsType');
    }

    const parsedData = HEAD.map(
      ({ _id, url, text, printPage, styleClass, createdAt }) => ({
        _id,
        url,
        text,
        styleClass,
        chapter: recordsType !== 'word' ? getChapterNameByURL(url, toc) : null,
        page: printPage,
        createdAt: formatUnixTime(createdAt),
      }),
    );

    onSetData(parsedData.reverse());
  }, [recordsType, bookmarks, highlights, words, toc, colorFilter, onSetData]);

  const handleFilterByColor = (styleClass: string) => {
    setColorFilter(styleClass);
  };

  const handledDeleteWords = (_ids: string[]) => {
    const wordsModel = new Words(bookMeta!.bid, () => {});

    const deletedIds = wordsModel.delete(_ids);
    const newWords = words.filter((word) => !deletedIds.includes(word._id));
    setWords(newWords);
  };

  return (
    <>
      <Wrapper isEditing={isEditing} id="records-wrapper">
        {recordsType === 'highlight' && (
          <ColorSelectBox
            styleType="filter"
            selectedColor={colorFilter}
            onSelect={handleFilterByColor}
          />
        )}
        {recordsType !== 'word' &&
          data.map(
            ({ _id, text, url, styleClass, chapter, page, createdAt }, idx) => (
              <AnnotationItem
                idx={idx}
                key={`${recordsType}-${idx + url}`}
                _id={_id}
                type={recordsType}
                text={text}
                url={url}
                styleClass={styleClass}
                chapter={chapter}
                page={page}
                createdAt={createdAt}
                recordsType={recordsType}
                isEditing={isEditing}
                isSelected={selections.has(idx)}
                onSelect={onSelect}
                onClose={onClose}
              />
            ),
          )}
        {recordsType === 'word' && (
          <WordsWrapper>
            {data.map(({ _id, text, url }, idx) => (
              <WordItem
                idx={idx}
                key={`${recordsType}-${idx + url}`}
                _id={_id}
                text={text}
                isEditing={isEditing}
                isSelected={selections.has(idx)}
                onSelect={onSelect}
                onDeleteWords={handledDeleteWords}
              />
            ))}
          </WordsWrapper>
        )}
        {data.length === 0 && (
          <NoneDataWrapper>
            <NoneDataInfoLabel>
              {
                {
                  bookmark: t('epub_record_no_result_bookmark'),
                  highlight: t('epub_record_no_result_highlight'),
                  word: t('epub_record_no_result_word'),
                }[recordsType]
              }
            </NoneDataInfoLabel>
          </NoneDataWrapper>
        )}
      </Wrapper>
      {isEditing && (
        <Button
          styleType="danger"
          disabled={selections.size === 0}
          style={{
            width: `calc(100% - 6.4rem)`,
            position: 'absolute',
            left: 32,
            bottom: 32,
          }}
          onClick={() => setIsDeleting(true)}
        >
          {selections.size === 0 &&
            {
              bookmark: t('epub_record_edit_button_choice_bookmark'),
              highlight: t('epub_record_edit_button_choice_highlight'),
              word: t('epub_record_edit_button_choice_word'),
            }[recordsType]}
          {selections.size !== 0 &&
            t('epub_record_edit_button_delete', { count: selections.size })}
        </Button>
      )}
      <RecordDeleteModal
        recordsType={recordsType}
        selectedData={selectedData}
        selections={selections}
        onResetSelections={onResetSelections}
        onSetIsEditing={onSetIsEditing}
        onDeleteWords={handledDeleteWords}
        isDeleting={isDeleting}
        onSetIsDeleting={(flag: boolean) => setIsDeleting(flag)}
      />
    </>
  );
}

export default RecordsList;

import React from 'react';
import { useTheme } from 'styled-components';
import { useRecoilValue } from 'recoil';
import {
  ViewerState,
  MultiColumnState,
  CurrentViewModeState,
} from '../../../state';
import * as Icon from '../../../assets/icons';
import usePageControl from './hooks/usePageControl';
import { ArrowButton } from './PageControl.style';

function PageControl() {
  const theme = useTheme();

  const viewer = useRecoilValue(ViewerState);
  const multiColumn = useRecoilValue(MultiColumnState);
  const currentViewMode = useRecoilValue(CurrentViewModeState);

  usePageControl();

  if (currentViewMode === 'scroll') return null;

  return (
    <>
      <ArrowButton
        id="page-control-left"
        isMultiColumn={multiColumn ?? false}
        direction="left"
        onClick={() => viewer?.prevPage()}
      >
        <Icon.ArrowChevronLeft
          fill={theme.colorTokens.hierarchy.h4}
          width="4rem"
          height="4rem"
        />
      </ArrowButton>
      <ArrowButton
        id="page-control-right"
        isMultiColumn={multiColumn ?? false}
        direction="right"
        onClick={() => viewer?.nextPage()}
      >
        <Icon.ArrowChevronRight
          fill={theme.colorTokens.hierarchy.h4}
          width="4rem"
          height="4rem"
        />
      </ArrowButton>
    </>
  );
}

export default PageControl;

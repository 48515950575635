import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import * as typography from '@tapas/typography';
import { useRecoilValue } from 'recoil';
import { ThemeState, MultiColumnState, PagingModeState } from '../../../state';
import { MenuItem } from '../../layouts/Header/RightSide/Menu';
import * as Icon from '../../../assets/icons';
import { Item } from '../../layouts/Header/RightSide/types';
import { useOnClickOutside } from '../../../hooks';
import {
  changeToScrollView,
  changeToOnePageView,
  changeToTwoPageView,
} from '../../../helpers/paging';
import { OnlyOneSideViewQuery } from '../../../styles/responsive';

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: 6.2rem;
  right: 3.2rem;
  width: 24rem;
  padding: 0.8rem;
  box-shadow: -3px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colorTokens.background.secondary};
`;

const Span = styled.span`
  display: block;
  margin-top: 0.8rem;
  padding: 1.6rem 0 0.8rem 1.6rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gray3};

  ${({ theme }) => typography.caption(theme.fonts.default, 400)};
  color: ${({ theme }) => theme.colors.gray5};
`;

interface DropdownProps {
  items: Item[];
  onClose: Function;
}

function Dropdown({ items, onClose }: DropdownProps) {
  const theme = useRecoilValue(ThemeState);
  const multiColumn = useRecoilValue(MultiColumnState);
  const pagingMode = useRecoilValue(PagingModeState);
  const isOnlyOnePage = useMediaQuery({ query: OnlyOneSideViewQuery });

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  const { t } = useTranslation();

  useEffect(() => {
    const contextMenu = document.getElementById('context-menu');
    contextMenu?.classList.remove('is-active');
  }, []);

  return (
    <>
      <Overlay />
      <Wrapper ref={ref}>
        <ul>
          {items.map(({ name, icon, onClick }: Item) => (
            <li key={`viewer-menu-${name}`}>
              <MenuItem
                layout="mobile"
                name={name}
                onClick={(e) => {
                  onClick(e);
                  onClose();
                }}
              >
                {icon}
              </MenuItem>
            </li>
          ))}
        </ul>
        <Span>{t('epub_tool_bar_more_view_mode_option')}</Span>
        <MenuItem
          layout="mobile"
          name={t('epub_viewmode_toast_scroll')}
          onClick={() => {
            changeToScrollView();
          }}
          style={{ position: 'relative' }}
        >
          <Icon.ScrollView stroke={theme === 'light' ? '#212529' : '#fff'} />
          {pagingMode === 'scroll' && (
            <Icon.Check style={{ position: 'absolute', right: '16' }} />
          )}
        </MenuItem>
        <MenuItem
          layout="mobile"
          name={t('epub_viewmode_toast_one_page')}
          onClick={() => {
            changeToOnePageView();
          }}
          style={{ position: 'relative' }}
        >
          <Icon.OneSideView stroke={theme === 'light' ? '#212529' : '#fff'} />
          {pagingMode === 'page' && !multiColumn && (
            <Icon.Check style={{ position: 'absolute', right: '16' }} />
          )}
        </MenuItem>
        <MenuItem
          layout="mobile"
          name={t('epub_viewmode_toast_two_page')}
          onClick={() => {
            changeToTwoPageView();
          }}
          style={{ position: 'relative' }}
          disabled={isOnlyOnePage}
        >
          <Icon.BothSideView stroke={theme === 'light' ? '#212529' : '#fff'} />
          {pagingMode === 'page' && multiColumn && (
            <Icon.Check style={{ position: 'absolute', right: '16' }} />
          )}
        </MenuItem>
      </Wrapper>
    </>
  );
}

export default Dropdown;

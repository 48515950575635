import styled from 'styled-components';
import * as typography from '@tapas/typography';

export const Wrapper = styled.div<{ from?: string }>`
  width: 100%;
  height: ${({ from }) => (from !== 'word-info' ? '42rem' : '51.7rem')};
  overflow: auto;
  padding: 3.2rem;

  @media screen and (max-width: 481px) {
    height: ${({ from }) => (from !== 'word-info' ? '34rem' : '43.7rem')};
  }
`;

export const SearchResultLabel = styled.span`
  display: inline-block;
  padding-bottom: 0.8rem;

  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
  ${({ theme }) => typography.body(theme.fonts.default, 1.4, 400)};
`;

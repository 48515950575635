import React, { useState, useLayoutEffect } from 'react';
import { Vocabulary } from '@ipf-dev/web-spindle-design-system';
import styled from 'styled-components';
import WordDetails from '../WordDetails';

interface WordItemProps {
  idx: number;
  _id: string;
  text: string;
  isEditing: boolean;
  isSelected: boolean;
  onSelect: Function;
  onDeleteWords: Function;
}

function WordItem({
  idx,
  _id,
  text,
  isEditing,
  isSelected,
  onSelect,
  onDeleteWords,
}: WordItemProps) {
  const [isWordInfoOpen, setWordInfoOpen] = useState(false);
  const [computedWidth, setComputedWidth] = useState<number>(0);
  const [overTwoWordsBoundary, setOverTwoWordsBoundary] = useState<boolean>(
    false,
  );

  useLayoutEffect(() => {
    const wordsWrapper = document.getElementById('records-wrapper')!;

    const resizeWordBox = () => {
      const containerWidth = parseInt(
        window.getComputedStyle(wordsWrapper).width,
        10,
      );
      const wordBoxMinWidth = 160;
      const threeWordsBoundary = wordBoxMinWidth * 3 + 96;
      const twoWordsBoundary = wordBoxMinWidth * 2 + 80;
      const correction = 2;
      let result = 0;

      if (containerWidth > threeWordsBoundary) {
        result = Math.floor(containerWidth - 96) / 3;
        setComputedWidth(result - correction);
      }

      if (containerWidth < threeWordsBoundary) {
        result = Math.floor(containerWidth - 80) / 2;
        setComputedWidth(result - correction);
      }

      if (containerWidth < twoWordsBoundary) {
        result = Math.floor(containerWidth - 64);
        setComputedWidth(result - correction);
        setOverTwoWordsBoundary(true);
      } else {
        setOverTwoWordsBoundary(false);
      }
    };

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        resizeWordBox();
      });
    });

    observer.observe(wordsWrapper);
  }, []);

  return (
    <Wrapper>
      <Vocabulary
        word={text}
        isEditing={isEditing}
        isSelected={isSelected}
        onClick={
          isEditing
            ? (e) => onSelect(e, idx)
            : () => setWordInfoOpen(!isWordInfoOpen)
        }
        style={{
          width: `${computedWidth}px`,
          margin: overTwoWordsBoundary ? '0 auto' : '0',
        }}
      />
      <WordDetails
        _id={_id}
        word={text}
        visible={isWordInfoOpen}
        onClose={() => setWordInfoOpen(!isWordInfoOpen)}
        onDeleteWords={onDeleteWords}
      />
    </Wrapper>
  );
}

export default WordItem;

const Wrapper = styled.div`
  span {
    font-family: 'Poppins';
  }
`;

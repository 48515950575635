import axios from 'axios';
import { resolveDomain } from '../../utils';

const defaultClient = axios.create({
  baseURL: `${process.env.REACT_APP_EPUB_API_URL}${resolveDomain(
    document.location.host,
  )}`,
  withCredentials: true,
});

export default defaultClient;

import styled, { css } from 'styled-components';
import * as typography from '@tapas/typography';

export const Wrapper = styled.div<{ isEditing: boolean }>`
  height: 51.8rem;
  padding: 1.6rem 3.2rem 0 3.2rem;
  padding-bottom: ${({ isEditing }) => (isEditing ? '12rem' : '3.2rem')};
  overflow: auto;

  @media screen and (max-width: 481px) {
    height: 43.8rem;
  }
`;

export const WordsWrapper = styled.div`
  padding-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 1.8rem;
  column-gap: 1.8rem;
`;

export const NoneDataWrapper = styled.div`
  height: 48rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoneDataInfoLabel = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colorTokens.hierarchy.h3};

  ${({ theme }) => typography.body(theme.fonts.default, 1.4, 700)}
`;

import styled, { css } from 'styled-components';
import * as typography from '@tapas/typography';

interface TabNavItemStyle {
  active: boolean;
}

const ActiveTabNavItem = css`
  font-weight: 700;
  border-bottom-color: ${({ theme }) => theme.colors.gray6};
`;

export const TabNavItem = styled.li<TabNavItemStyle>`
  ${({ theme }) => typography.body(theme.fonts.default, 1.6)}

  flex: 1;
  display: inline-block;
  box-sizing: border-box;
  padding: 1.6rem 0;
  margin: 0 1.6rem;
  border-bottom: 2px solid transparent;
  ${({ active }) => active && ActiveTabNavItem}

  color: ${(props) => props.theme.colors.gray6};
  text-decoration: none;
  text-align: center;
  white-space: nowrap;

  cursor: pointer;
  transition: border-bottom-color 0.1s linear;

  &:hover {
    border-bottom-color: ${({ theme, active }) => !active && theme.colors.gray4};
  }
`;

interface TabNavContainerStyle {
  fullWidth: boolean;
}

const DefaultNavContainer = css`
  justify-content: flex-start;

  li:first-child {
    margin-left: 0;
  }

  li:last-child {
    margin-right: 0;
  }
`;

const FullWidthNaveContainer = css`
  justify-content: space-between;

  li {
    flex-grow: 1;
  }
`;

export const TabNavContainer = styled.ul<TabNavContainerStyle>`
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray3};
  list-style: none;

  display: flex;

  ${({ fullWidth }) =>
    fullWidth ? FullWidthNaveContainer : DefaultNavContainer}
`;

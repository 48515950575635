import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { BookMetaState } from '../../../../state';
import Words from '../../../../models/Words';
import {
  Wrapper,
  Iframe,
  DeleteButton,
  AddingButton,
} from './Dictionary.style';
import SearchInfo from '../SearchResultList/templates/SearchInfo';
import Toast from '../../../commons/EpubToast';
import { hasSpaces } from '../../../../utils';
import { From } from './types';
import { searchDictionaryFor } from '../../../../api';

interface DictionaryProps {
  word: string;
  from: From;
}

function Dictionary({ word, from }: DictionaryProps) {
  const bookMeta = useRecoilValue(BookMetaState);
  const [isAddingButtonOpen, setAddingButtonOpen] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [createdId, setCreatedId] = useState<string>('');
  const [doc, setDoc] = useState<string>('');
  const [isFound, setIsFound] = useState<boolean | null>(null);

  const wordsRef = useRef<Words | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const requestDictionarySearching = async (query: string) => {
    if (!query) return;

    const { data } = await searchDictionaryFor(query);
    const contentDoc = data as string;
    const parser = new DOMParser();
    const contentDocDom = parser.parseFromString(contentDoc, 'text/html');
    const hasWordInfo = !contentDocDom.getElementById('no-results');

    setIsFound(hasWordInfo);

    const changeHost = () =>
      contentDoc.replaceAll(
        /('|")\/resources/g,
        `$1${process.env.REACT_APP_RDN_API_HOST}/resources`,
      );

    if (hasWordInfo) {
      const newContentDoc = changeHost();
      setDoc(newContentDoc);
    } else {
      setDoc('');
    }
  };

  useEffect(() => {
    setIsAdding(false);
    requestDictionarySearching(word);
  }, [word]);

  useEffect(() => {
    wordsRef.current = new Words(bookMeta!.bid, () =>
      console.log('recoil setter로 바꿔주세요.'),
    );
  }, [bookMeta]);

  const addOrDeleteWord = () => {
    if (!isAdding) {
      const id = wordsRef.current!.create(word);
      setCreatedId(id);
      Toast().success(t('epub_toast_add_word'));
    } else {
      wordsRef.current!.delete([createdId]);
      setCreatedId('');
      Toast().success(t('epub_toast_add_cancel'));
    }
    setIsAdding(!isAdding);
  };

  const onLoad = () => {
    const isFromWordDetails = from === 'word-details';

    if (isFromWordDetails) {
      setAddingButtonOpen(false);
    } else {
      setAddingButtonOpen(true);
    }
  };

  if (from === 'search' && !word) return <SearchInfo type="default" />;

  if (from === 'search' && hasSpaces(word))
    return <SearchInfo type="onlyWord" />;

  if (from === 'search' && isFound === false)
    return <SearchInfo type="notFound" query={word} />;

  return (
    <Wrapper ref={wrapperRef} from={from}>
      <Iframe title="OUP-DICTIONARY" srcDoc={doc} onLoad={onLoad} />
      {isAddingButtonOpen && isAdding && (
        <DeleteButton onClick={addOrDeleteWord}>
          {t('epub_search_button_add_cancel')}
        </DeleteButton>
      )}
      {isAddingButtonOpen && !isAdding && (
        <AddingButton onClick={addOrDeleteWord}>
          {t('epub_search_button_add_word')}
        </AddingButton>
      )}
    </Wrapper>
  );
}

export default Dictionary;

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { PageInfoState } from '../../../state';
import { detectMobileDevice } from '../../../utils';
import { onDeselect, onSelect } from '../functions';

interface Context {
  selectionInfo?: any;
}

function useMobileContextMenu() {
  const isMobile = detectMobileDevice(window.navigator.userAgent);

  const pageInfo = useRecoilValue(PageInfoState);
  const [context, setContext] = useState<Context>({});

  useEffect(() => {
    if (!isMobile) return () => {};

    const handleSendSelectionInfo = (event: any) => {
      setContext((prev) => ({ ...prev, selectionInfo: event.detail }));
    };

    document.querySelectorAll('iframe').forEach((iframe) => {
      if (!iframe.contentDocument) return;

      iframe.contentDocument.addEventListener(
        'sendSelectionInfo',
        handleSendSelectionInfo,
      );
    });

    return () => {
      document.querySelectorAll('iframe').forEach((iframe) => {
        if (!iframe.contentDocument) return;

        iframe.contentDocument.removeEventListener(
          'sendSelectionInfo',
          handleSendSelectionInfo,
        );
      });
    };
  }, [pageInfo]);

  useEffect(() => {
    if (!isMobile) return;

    const { selectionInfo } = context;
    if (!selectionInfo) return;

    const selectedText = selectionInfo.event.selection?.range.toString().trim();

    if (selectedText) {
      onSelect(context, selectionInfo.contextMenuRef);
    } else {
      onDeselect(selectionInfo.contextMenuRef);
    }
  }, [context.selectionInfo]);
}

export default useMobileContextMenu;

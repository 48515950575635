import React, { useState, useEffect } from 'react';
import { BukViewer, ItemLoadEvent, PageInfoChangeEvent } from 'buk-viewer';
import { GrayText, PageLabel, Wrapper } from './PageNavigator.style';
import { PageNavigatorType } from './types';
import Slider from './Slider';

interface PageNavigatorProps {
  visible: boolean;
  styleType: PageNavigatorType;
  itemInfo: ItemLoadEvent | null;
  pageInfo: PageInfoChangeEvent;
  hasPrintPageInfo: boolean;
  viewer: BukViewer | null;
}

function PageNavigator({
  visible,
  styleType,
  itemInfo,
  pageInfo,
  hasPrintPageInfo,
  viewer,
}: PageNavigatorProps) {
  const [value, setValue] = useState(0);
  const [finalValue, setFinalValue] = useState(0);

  const onChange = (changedValue: number) => {
    setValue(changedValue);
  };

  const onMouseUp = (e: any) => {
    const selectedValue: number = parseInt(e.target.value, 10);

    if (selectedValue === 0) return;

    if (hasPrintPageInfo) {
      viewer?.goToPrintPage(selectedValue);
      setFinalValue(selectedValue);
    } else {
      if (pageInfo.page === selectedValue) return;

      const page = (selectedValue - 1) / pageInfo.pageCount;

      viewer?.openBookWithURL(
        `${itemInfo?.address.bid}/${itemInfo?.address.iid}/${page}`,
      );
    }
  };

  useEffect(() => {
    const { page, printPages } = pageInfo;

    if (hasPrintPageInfo) {
      const { length } = printPages;
      const lastPrintPage = printPages[length - 1];

      if (printPages.includes(finalValue)) {
        setValue(finalValue);
        setFinalValue(0);
      } else {
        setValue(lastPrintPage);
      }
    } else {
      setValue(page);
    }
  }, [pageInfo, hasPrintPageInfo]);

  if (!visible) return null;

  return (
    <Wrapper styleType={styleType}>
      <Slider
        styleType={styleType}
        min="0"
        max={
          hasPrintPageInfo ? pageInfo.printPageCount ?? 0 : pageInfo.pageCount
        }
        step="1"
        value={value}
        onChange={onChange}
        onMouseUp={onMouseUp}
      />
      <PageLabel styleType={styleType}>
        {value}
        <GrayText styleType={styleType}>
          {' / '}
          {hasPrintPageInfo ? pageInfo.printPageCount : pageInfo.pageCount}
        </GrayText>
      </PageLabel>
    </Wrapper>
  );
}

export default PageNavigator;

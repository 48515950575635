import React from 'react';
import reactStringReplace from 'react-string-replace';
import {
  Wrapper,
  Sentence,
  HighlightedWord,
  SentenceInfo,
} from './SentenceList.style';
import { SentenceListProps } from './types';

function SentenceList({
  word,
  sentence,
  sentenceInfo,
  onClick,
}: SentenceListProps) {
  const renderSentence = () => {
    const regex = new RegExp(`(${word})`, 'gi');

    return reactStringReplace(sentence, regex, (match, i) => (
      <HighlightedWord key={match + i}>{match}</HighlightedWord>
    ));
  };

  return typeof sentence !== 'undefined' ? (
    <Wrapper>
      <Sentence
        canClick={typeof onClick !== 'undefined'}
        onClick={onClick ? () => onClick() : () => {}}
      >
        {renderSentence()}
      </Sentence>
      <SentenceInfo>{sentenceInfo}</SentenceInfo>
    </Wrapper>
  ) : null;
}

export default SentenceList;

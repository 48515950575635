import styled, { css } from 'styled-components';
import * as typography from '@tapas/typography';
import { From } from './types';

export const Wrapper = styled.div<{ from?: From }>`
  position: relative;
  width: 100%;
  height: ${({ from }) => (from !== 'word-details' ? '42.5rem' : '51.3rem')};

  @media screen and (max-width: 481px) {
    height: ${({ from }) => (from !== 'word-details' ? '34.5rem' : '43.3rem')};
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;

  padding: 3.2rem;
`;

const Button = styled.button.attrs({ type: 'button' })`
  position: absolute;
  bottom: 3.2rem;
  right: 3.2rem;
  width: 15rem;
  padding: 1.6rem 0;
  border-radius: 0.8rem;
  ${({ theme }) => typography.body(theme.fonts.default, 1.4, 700)}
`;

export const AddingButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  :hover {
    background-color: ${({ theme }) => theme.colors.primaryLighten};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.primaryDarken};
  }
`;

const lightDeleteButtonStyle = (theme: any) => css`
  color: ${theme.colors.gray6};
  background-color: ${theme.colors.gray2};
  :hover {
    background-color: ${theme.colors.gray1};
  }
  :active {
    background-color: ${theme.colors.gray3};
  }
`;

const darkDeleteButtonStyle = (theme: any) => css`
  color: ${theme.colors.primary};
  background-color: ${theme.custom.popover};
  :hover {
    background-color: ${theme.custom.popover};
  }
  :active {
    background-color: ${theme.custom.popover};
  }
`;

export const DeleteButton = styled(Button)`
  ${({ theme }) =>
    theme.colorTokens.mode === 'light'
      ? lightDeleteButtonStyle(theme)
      : darkDeleteButtonStyle(theme)};
`;

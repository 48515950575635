import { atom } from 'recoil';

interface MediaInfo {
  isPlaying: boolean;
  playbackRate: number;
}

export default atom<MediaInfo>({
  key: 'MediaInfo',
  default: {
    isPlaying: false,
    playbackRate: 1.0,
  },
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Icon, ModalDialog } from '@ipf-dev/web-spindle-design-system';
import { useRecoilValue } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { tabletQuery } from '../../../styles/responsive';
import {
  BookMetaState,
  BookTocState,
  ItemInfoState,
  ViewerState,
} from '../../../state';
import {
  Wrapper,
  MetaBox,
  BookCover,
  BookInfoBox,
  BookTitleLabel,
  BookAuthorLabel,
  TocBox,
  ContentItem,
  ItemLabel,
  ItemDivider,
  Divider,
} from './TableOfContents.style';

interface TableOfContentsProps {
  isTocOpen: boolean;
  onClose: Function;
}

function TableOfContents({ isTocOpen, onClose }: TableOfContentsProps) {
  const bookMeta = useRecoilValue(BookMetaState);
  const bookToc = useRecoilValue(BookTocState);
  const viewer = useRecoilValue(ViewerState);
  const itemInfo = useRecoilValue(ItemInfoState);

  const isTablet = useMediaQuery({ query: tabletQuery });
  const theme = useTheme();
  const { t } = useTranslation();

  const goToItem = (iid: string) => {
    const url = `/@${bookMeta?.bid}/${!iid ? 'cover' : iid}`;

    viewer?.openBookWithURL(url);
    onClose();
  };

  return (
    <ModalDialog
      styleType="header"
      headerOptions={{
        title: t('epub_toc_title'),
        right: {
          label: (
            <Icon.XShape
              styleType="close"
              color={theme.colorTokens.hierarchy.h1}
            />
          ),
          onClick: () => {
            onClose();
          },
        },
      }}
      visible={isTocOpen}
      onClose={() => onClose()}
      style={{
        height: '64rem',
        width: `${isTablet ? 'calc(100% - 12.8rem)' : 'calc(100% - 6.4rem)'}`,
        maxWidth: '64rem',
        zIndex: 8000,
      }}
    >
      <Wrapper>
        <MetaBox>
          <BookCover src={bookMeta?.cover} />
          <BookInfoBox>
            <BookTitleLabel>{bookMeta?.title}</BookTitleLabel>
            <BookAuthorLabel>
              {window.bookData?.level?.title && window.bookData?.series?.title
                ? `${window.bookData.level.title} / ${window.bookData.series.title}`
                : bookMeta?.author}
            </BookAuthorLabel>
          </BookInfoBox>
        </MetaBox>
        <Divider />
        <TocBox>
          {bookToc?.map((item) => (
            <div key={`toc-${item.address}`}>
              <ContentItem
                isActive={item.address === (itemInfo?.address.iid ?? 'cover')}
                onClick={() => {
                  goToItem(item.address);
                }}
              >
                <ItemLabel>{item.title}</ItemLabel>
              </ContentItem>
              <ItemDivider />
            </div>
          ))}
        </TocBox>
      </Wrapper>
    </ModalDialog>
  );
}

export default TableOfContents;

/* eslint-disable indent */
import styled, { css } from 'styled-components';
import responsive from '../../../styles/responsive';
import { convertHexToRGB } from '../../../utils';

export const Container = styled.div`
  border-radius: 2rem;

  ${responsive.conditionForMobileRightSide} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 15.6rem;
  height: 4rem;
  display: grid;
  place-items: center;
`;

export const UL = styled.ul`
  position: relative;
  width: 15.6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 35px;
  overflow: hidden;
  background: ${({ theme }) =>
    theme.colorTokens.mode === 'light'
      ? theme.colors.gray2
      : 'rgba(255, 255, 255, 0.12)'};
`;

const ListHoverStyle = (theme: any) => css`
  &:hover {
    background-color: ${theme.colorTokens.mode === 'light'
      ? theme.colors.gray3
      : 'rgba(255, 255, 255, 0.12)'};

    svg {
      stroke: ${theme.colors.gray6};
    }
  }
`;

const ListActiveStyle = (theme: any) => css`
  &:active {
    background-color: ${theme.colorTokens.mode === 'light'
      ? convertHexToRGB(theme.colors.primary, 0.38)
      : convertHexToRGB(theme.colors.primaryLighten, 0.16)};
  }
`;

export const List = styled.li<{ isActive: boolean }>`
  list-style: none;
  display: grid;
  padding: 0.8rem 0;
  place-items: center;
  z-index: 10;
  cursor: pointer;
  border-radius: 2rem;

  ${({ theme, isActive }) => !isActive && ListHoverStyle(theme)}
  ${({ theme }) => ListActiveStyle(theme)}
`;

export const TRANSITION_DURATION = 0.4;

export const Focus = styled.div`
  position: absolute;
  z-index: 3;
  width: 5.2rem;
  height: 4rem;
  border-radius: 2rem;
  transition: left ${TRANSITION_DURATION}s ease-in-out,
    width ${TRANSITION_DURATION}s ease-in-out;

  ${({ theme }) =>
    `background-color: ${
      theme.colorTokens.mode === 'light'
        ? convertHexToRGB(theme.colors.primary, 0.38)
        : convertHexToRGB(theme.colors.primaryLighten, 0.16)
    };`}
`;

import styled from 'styled-components';
import { HeaderProps } from './types';

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
`;

export const Wrapper = styled.div<HeaderProps>`
  display: flex;
  width: 100%;
  height: 7rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem 2.4rem;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background-color: ${({ theme }) => theme.colorTokens.background.primary};
  border-bottom: 0.1rem solid transparent;
  border-color: ${({ theme }) => theme.colorTokens.hierarchy.h4};
`;

export const BookmarkInfo = styled.div<HeaderProps>`
  position: fixed;
  top: 0;
  right: 2.78%;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

import invariant from 'tiny-invariant';
import Annotations from '../models/Annotations';
import { parseBukURL } from './url';

export const CUSTOM_deleteAnnotationById = async (
  _id: string | string[],
  _bid: string,
) => {
  // 저장소(localStorage)의 annotation 삭제
  const bid = _bid.replace(/@/, '');

  const annotations = await Annotations.CUSTOM_getAll(bid);

  let deletedAnnotations = [];
  if (typeof _id === 'object') {
    deletedAnnotations = annotations.filter((item) => _id.includes(item._id));
    _id.forEach((id) => Annotations.CUSTOM_remove(bid, id));
  } else {
    deletedAnnotations = annotations.filter((item) => item._id === _id);
    Annotations.CUSTOM_remove(bid, _id);
  }

  // 삭제 작업 완료시, 삭제한 annotation 객체를 전송
  const event = new CustomEvent('annotationRemoved', {
    detail: {
      annotations: deletedAnnotations,
    },
  });
  const viewer = document.querySelector('buk-viewer');
  viewer?.dispatchEvent(event);
};

export const CUSTOM_changeHighlightColorById = async (
  _id: string,
  url: string,
  styleClass: string,
) => {
  const { bid } = parseBukURL(url);
  invariant(bid, 'Not Valid Buk URL');

  const parsedBid = bid.replace(/@/, '');
  const currentAnnotation = await Annotations.CUSTOM_get(parsedBid, _id);

  const updatedAnnotation = {
    ...currentAnnotation,
    styleClass,
  };

  const event = new CustomEvent('annotationChanged', {
    detail: {
      annotation: updatedAnnotation,
    },
  });
  const viewer = document.querySelector('buk-viewer');
  viewer?.dispatchEvent(event);
};

import { useEffect, useRef } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import * as S from '../../state';
import { connectWithBookshelf, startInDevMode } from './functions';

function useBukio() {
  const setViewer = useSetRecoilState(S.ViewerState);
  const setBookInfo = useSetRecoilState(S.BookInfoState);
  const setPageInfo = useSetRecoilState(S.PageInfoState);
  const setSettings = useSetRecoilState(S.SettingsState);
  const setMediaInfo = useSetRecoilState(S.MediaInfoState);
  const setAnnotations = useSetRecoilState(S.AnnotationsState);
  const setBookmark = useSetRecoilState(S.BookmarkState);
  const setItemInfo = useSetRecoilState(S.ItemInfoState);
  const setSelectedText = useSetRecoilState(S.SelectedTextState);
  const setPopupOpen = useSetRecoilState(S.PopupOpenState);

  const pageInfo = useRecoilValue(S.PageInfoState);
  const selectedText = useRecoilValue(S.SelectedTextState);

  const annotationsModel = useRef();
  const contextMenuRef = useRef();
  const contextRef = useRef<any>();

  const recoilSetters = {
    setViewer,
    setBookInfo,
    setPageInfo,
    setSettings,
    setMediaInfo,
    setAnnotations,
    setBookmark,
    setItemInfo,
    setSelectedText,
    setPopupOpen,
  };

  contextRef.current = {
    pageInfo,
    selectedText,
  };

  const refs = {
    annotationsModel,
    contextMenuRef,
    contextRef,
  };

  useEffect(() => {
    if (window.opener) {
      connectWithBookshelf(recoilSetters, refs); // init from bookshelf
      return;
    }

    if (window.location.pathname.split('/')[1] === 'test') {
      // for test in stage
      startInDevMode(recoilSetters, refs);
    }

    // if (process.env.NODE_ENV === 'development') {
    //   startInDevMode(recoilSetters, refs);
    // }
  }, []);
}

export default useBukio;

import React from 'react';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import responsive from '../../../../../styles/responsive';
import { Item } from '../types';

const Wrapper = styled.div`
  display: inline-block;

  ${responsive.conditionForMobileRightSide} {
    display: none;
  }
`;

const List = styled.li`
  display: inline-block;
  margin-left: min(5.6rem, 3.5vw);

  &:first-child {
    margin-left: 0;
  }
`;

interface MenuForDesktopProps {
  items: Item[];
}

function MenuForDesktop({ items }: MenuForDesktopProps) {
  return (
    <Wrapper>
      <ul>
        {items.map(({ name, icon, onClick }: Item) => (
          <List key={`viewer-menu-${name}`}>
            <MenuItem layout="desktop" name={name} onClick={onClick}>
              {icon}
            </MenuItem>
          </List>
        ))}
      </ul>
    </Wrapper>
  );
}

export default MenuForDesktop;

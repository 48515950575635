import { BukViewer } from 'buk-viewer';
import { t } from 'i18next';
import { copyToClipboard } from '../../../utils';
import { makeShareURL, AnnotationInfo } from '../../../helpers';
import { highlightColors } from '../viewer.config';
import Toast from '../../../components/commons/EpubToast';

export function onAnnotationsChange(
  _annotations: any,
  viewer: BukViewer,
  onSetAnnotations: Function,
) {
  console.log('ALL annotations changed', _annotations);

  viewer.setAnnotations(_annotations);

  onSetAnnotations([..._annotations]);
}

export function onHighlightMenuClick({ context, styleClass }: any) {
  console.debug(context);
  const viewer: BukViewer = document.querySelector('buk-viewer')!;

  if (context.annotation) {
    viewer.changeHighlight(
      context.annotation.url,
      context.annotation.styleClass,
      styleClass,
    );
  } else {
    viewer.createHighlight(context.selectedRange, styleClass, {
      mergeWith: Object.keys(highlightColors),
    });
  }

  viewer.clearSelection();
}

export function onMenuClick({ action, context }: any, refs: any, setters: any) {
  const viewer: BukViewer = document.querySelector('buk-viewer')!;

  switch (action) {
    case 'delete': {
      viewer.removeHighlight(
        context.annotation.url,
        context.annotation.styleClass,
      );
      viewer.clearSelection();
      break;
    }
    case 'listen': {
      viewer.playMediaOverlayFromRange(context.selectedRange);
      setters.setPopupOpen((...prev: any) => ({ ...prev, playButton: false }));
      viewer.clearSelection();
      break;
    }
    case 'search': {
      if (typeof context.annotation !== 'undefined') {
        setters.setSelectedText((prev: any) => ({
          ...prev,
          text: context.annotation.text,
        }));
      }
      setters.setPopupOpen((...prev: any) => ({ ...prev, search: true }));
      viewer.clearSelection();
      break;
    }
    case 'share': {
      if (context.annotation) {
        const { url, type, styleClass } = context.annotation;
        copyToClipboard(
          makeShareURL({ url, type, styleClass } as AnnotationInfo),
        ) && Toast().success(t('epub_toast_share'));
      } else {
        const url = refs.contextRef.current.selectedText.url;
        copyToClipboard(makeShareURL({ url } as AnnotationInfo)) &&
          Toast().success(t('epub_toast_share'));
      }
      refs.contextMenuRef.current.hide();
      break;
    }
  }
}

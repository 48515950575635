import React from 'react';
import styled, { useTheme } from 'styled-components';
import * as typography from '@tapas/typography';
import { colorScales } from '@ipf-dev/web-spindle-design-system';

const Wrapper = styled.div``;

const Radio = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 7.4rem;
  height: 3.7rem;
  border-radius: 10rem;

  &:first-child {
    margin-right: 0.8rem;
  }

  &:after {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    content: 'Aa';
  }

  ${({ theme }) => typography.subheading(theme.fonts.default, 400)}

  &:checked {
    border: 0.2rem solid ${({ theme }) => theme.colorTokens.accent.default};
  }
`;

interface ThemeRadioProps {
  theme: string;
  onChangeTheme: Function;
}

function ThemeRadio({ theme, onChangeTheme }: ThemeRadioProps) {
  const _theme = useTheme();

  const onChangeValue = (event: any) => {
    onChangeTheme(event.target.value);
  };

  return (
    <Wrapper>
      <form onChange={onChangeValue}>
        <Radio
          defaultChecked={theme === 'light' && true}
          value="light"
          name="theme"
          style={{
            color: colorScales.gray90,
            backgroundColor: _theme.custom.backgroundLighten,
          }}
        />
        <Radio
          defaultChecked={theme === 'dark' && true}
          value="dark"
          name="theme"
          style={{
            color: colorScales.white,
            backgroundColor: _theme.custom.backgroundDarken,
          }}
        />
      </form>
    </Wrapper>
  );
}

export default ThemeRadio;

import React, { useState, useCallback } from 'react';
import { Icon, ModalDialog } from '@ipf-dev/web-spindle-design-system';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { tabletQuery } from '../../../styles/responsive';
import Tab from '../../commons/Tab';
import RecordsList from './RecordsList';
import { RecordsProps, ParsedData } from './types';
import { Label } from './Records.style';
import { useSelectedData, useEditingState } from './hooks';

function Records({ isRecordsOpen, onClose }: RecordsProps) {
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: tabletQuery });
  const { t } = useTranslation();

  const [data, setData] = useState<ParsedData[]>([]);
  const [selections, setSelections] = useState<Set<number>>(new Set());
  const selectedData = useSelectedData(data, selections);
  const [isEditing, setIsEditing] = useEditingState(
    useCallback(() => setSelections(new Set()), []),
  );

  const handleSetIsEditing = () => setIsEditing(!isEditing);

  const handleClose = () => {
    onClose();
    setIsEditing(false);
  };

  const handleSetData = useCallback(
    (annotations: ParsedData[]) => setData(annotations),
    [],
  );

  const handleSelectOne = (e: any, idx: number) => {
    setSelections((prevState) => {
      const newState = new Set(prevState);
      prevState.has(idx) ? newState.delete(idx) : newState.add(idx);
      return newState;
    });
  };

  const handleSelectAll = () => {
    const set = new Set<number>();
    data.forEach((item, idx) => set.add(idx));
    setSelections(set);
  };

  const handleResetSelections = () => {
    setSelections(new Set());
  };

  return (
    <ModalDialog
      styleType="header"
      headerOptions={{
        title: t('epub_record_title'),
        left: {
          label: isEditing ? (
            <Label>{t('epub_record_edit_select_all')}</Label>
          ) : (
            <Label>{data.length !== 0 && t('epub_record_edit')}</Label>
          ),
          onClick: () => {
            isEditing ? handleSelectAll() : setIsEditing(!isEditing);
          },
        },
        right: {
          label: isEditing ? (
            <Label>{t('epub_record_edit_cancel')}</Label>
          ) : (
            <Icon.XShape
              styleType="close"
              color={theme.colorTokens.hierarchy.h1}
            />
          ),
          onClick: () => {
            isEditing ? setIsEditing(!isEditing) : onClose();
          },
        },
      }}
      visible={isRecordsOpen}
      onClose={handleClose}
      style={{
        height: '64rem',
        width: `${isTablet ? 'calc(100% - 12.8rem)' : 'calc(100% - 6.4rem)'}`,
        zIndex: 8000,
        maxWidth: '64rem',
      }}
    >
      <Tab
        tabItems={[
          {
            id: 'tab1',
            title: t('epub_record_tab_bookmark'),
            contents: (
              <RecordsList
                recordsType="bookmark"
                isEditing={isEditing}
                onSetIsEditing={handleSetIsEditing}
                selections={selections}
                onResetSelections={handleResetSelections}
                onSelect={handleSelectOne}
                data={data}
                onSetData={handleSetData}
                selectedData={selectedData}
                onClose={handleClose}
              />
            ),
          },
          {
            id: 'tab2',
            title: t('epub_record_tab_highlight'),
            contents: (
              <RecordsList
                recordsType="highlight"
                isEditing={isEditing}
                onSetIsEditing={handleSetIsEditing}
                selections={selections}
                onResetSelections={handleResetSelections}
                onSelect={handleSelectOne}
                data={data}
                onSetData={handleSetData}
                selectedData={selectedData}
                onClose={handleClose}
              />
            ),
          },
          {
            id: 'tab3',
            title: t('epub_record_tab_my_words'),
            contents: (
              <RecordsList
                recordsType="word"
                isEditing={isEditing}
                onSetIsEditing={handleSetIsEditing}
                selections={selections}
                onResetSelections={handleResetSelections}
                onSelect={handleSelectOne}
                data={data}
                onSetData={handleSetData}
                selectedData={selectedData}
                onClose={handleClose}
              />
            ),
          },
        ]}
        styleType="fullwidth"
        options={{
          tabNavItemStyle: {
            color: `${theme.colorTokens.hierarchy.h1}`,
            margin: 0,
          },
        }}
      />
    </ModalDialog>
  );
}

export default Records;

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { CurrentViewModeState } from '../../../../state';
import { ViewMode } from '../types';
import { TRANSITION_DURATION } from '../ViewMode.style';

const getCurrentViewModeIndex = (currentViewMode: ViewMode) => {
  if (currentViewMode === 'both') return 0;
  if (currentViewMode === 'one') return 1;
  if (currentViewMode === 'scroll') return 2;
  return 1;
};

function useToggleAnimation() {
  const currentViewMode = useRecoilValue(CurrentViewModeState);

  const currentIndex = getCurrentViewModeIndex(currentViewMode);

  useEffect(() => {
    const focus = document.getElementById('viewmode-focus') as HTMLDivElement;
    const allModes = document.querySelectorAll(
      '#viewmode-ul li',
    ) as NodeListOf<HTMLLIElement>;

    const newViewModeLeft = allModes[currentIndex].offsetLeft;
    focus!.style.left = `${newViewModeLeft}px`;

    const handleClick = (e: MouseEvent) => {
      const CUSTOM_RATIO = 2;
      const ms = (TRANSITION_DURATION * 1000) / CUSTOM_RATIO;

      const viewModeLeft = (e.currentTarget as HTMLLIElement).offsetLeft;
      const isCurrentMode = viewModeLeft === newViewModeLeft;
      if (isCurrentMode) return;

      const viewModeWidth = (e.currentTarget as HTMLLIElement).offsetWidth;
      focus!.style.width = `${viewModeWidth * 2}px`;
      setTimeout(() => {
        focus!.style.width = `${viewModeWidth}px`;
      }, ms);
    };

    allModes.forEach((mode) => mode.addEventListener('click', handleClick));
    return () => {
      allModes.forEach((mode) =>
        mode.removeEventListener('click', handleClick),
      );
    };
  }, [currentViewMode]);
}

export default useToggleAnimation;

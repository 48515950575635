import styled from 'styled-components';
import * as typography from '@tapas/typography';
import { colorScales } from '@ipf-dev/web-spindle-design-system';
import { addAlpha } from '../../../../helpers';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 1.2rem 1.6rem;
  border-radius: 3.2rem;

  background-color: ${({ theme }) =>
    addAlpha(theme.epubColorTokens.playButton.default, 0.9)};

  :hover {
    background-color: ${({ theme }) =>
      addAlpha(theme.epubColorTokens.playButton.hover, 0.9)};
  }
  :active {
    background-color: ${({ theme }) =>
      addAlpha(theme.epubColorTokens.playButton.pressed, 0.9)};
  }
`;

export const Label = styled.span`
  display: inline-block;
  padding-left: 0.8rem;
  padding-right: 0.6rem;
  transform: translateY(0.1rem);
  color: ${colorScales.white};
  ${({ theme }) => typography.body(theme.fonts.default, 1.4, 700)}
`;

import { css } from 'styled-components';

const contextMenuStyle = css`
  #context-menu {
    position: fixed;
    z-index: 10;
    width: 19.2rem;
    display: block;

    background-color: #fff;
    box-shadow: 0 2rem 4.8rem rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
  }

  #context-menu:not(.is-active) {
    pointer-events: none;
    visibility: hidden;
  }

  #context-menu .highlights-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
  }

  #context-menu .highlights-box .menu-highlight-item {
    display: inline-block;
  }

  #context-menu .highlights-box .menu-highlight-item .menu-highlight-preview {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 0.8rem;
    border-radius: 50%;
  }

  #context-menu .menu-highlight-item .menu-highlight-preview:hover {
    border: 0.2rem solid #52cf29;
    border-radius: 50%;
  }

  #context-menu .menu-highlight-item.is-selected .menu-highlight-preview {
    border: 0.2rem solid #52cf29;
    border-radius: 50%;
  }

  #context-menu .actions-box .menu-action-item {
    padding: 1.6rem 2.4rem;
    color: #212529;
    font-size: 1.6rem;
    font-weight: 400;
    border-top: 0.1rem solid #dee2e6;
    user-select: none;
  }

  #context-menu .actions-box .menu-action-item:hover {
    background-color: #f8f9fa;
  }

  #context-menu .actions-box .menu-action-item:active {
    background-color: #f1f3f5;
  }

  #context-menu .actions-box .menu-action-item:last-child {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  #context-menu .menu-item.is-disabled {
    display: none;
  }

  #context-menu .menu-item {
    cursor: pointer;
  }

  /* dark */
  #context-menu.dark {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.12),
        rgba(255, 255, 255, 0.12)
      ),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.16),
        rgba(255, 255, 255, 0.16)
      ),
      #16191c;
  }

  #context-menu.dark .actions-box .menu-action-item {
    color: rgba(255, 255, 255, 0.87);
    border-top: 0.1rem solid #ffffff61;
  }

  #context-menu.dark .actions-box .menu-action-item:hover {
    background-color: #3b3e40;
  }

  #context-menu.dark .actions-box .menu-action-item:active {
    background-color: #3b3e40;
  }
`;

export default contextMenuStyle;

import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { RangeInput } from './Slider.style';
import { PageNavigatorType } from './types';

interface SliderProps {
  value: number | string;
  min: number | string;
  max: number | string;
  step: number | string;
  onChange: Function;
  onMouseUp: MouseEventHandler;
  styleType: PageNavigatorType;
  isDisabled?: boolean;
}

function Slider({
  value,
  min,
  max,
  step,
  onChange,
  onMouseUp,
  styleType,
  isDisabled = false,
}: SliderProps) {
  const theme = useTheme();

  const fill = useCallback(() => {
    const mySlider: any = document.getElementById('my-slider')!;

    const valPercent = (mySlider.value / mySlider.max) * 100;

    if (styleType === 'default') {
      mySlider.style.background = `linear-gradient(to right, ${theme.colors.primary} ${valPercent}%, ${theme.colorTokens.hierarchy.h4} ${valPercent}%)`;
    } else {
      mySlider.style.background = `linear-gradient(to right, rgba(255, 255, 255, 0.38) ${valPercent}%, rgba(255, 255, 255, 0.16) ${valPercent}%)`;
    }

    return valPercent;
  }, [styleType, theme]);

  const handleOnChange = (e: any) => {
    const changedValue = e.target.value;
    if (changedValue === '0') return;

    fill();
    onChange(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    fill();
  }, [value, max, fill]);

  return (
    <RangeInput
      type="range"
      id="my-slider"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleOnChange}
      onPointerUp={onMouseUp}
      styleType={styleType}
      disabled={isDisabled}
    />
  );
}

export default Slider;

import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export default function useEditingState(
  handler: Function,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    // isEditing || setSelections(new Set());
    isEditing || handler();
  }, [isEditing, handler]);

  return [isEditing, setIsEditing];
}

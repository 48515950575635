import invariant from 'tiny-invariant';
import { parseBukURL, parseShareURLQueryString } from '../helpers';

export default class ShareInfo {
  static readonly keyName: string = 'shared-url';

  static saveByDetectedURL() {
    const { bid, iid, pageOrAnchor } = parseBukURL(document.location.pathname);

    if (!bid) invariant(false, `saving share info error - bid is ${bid}`);
    if (!iid) invariant(false, `saving share info error - iid is ${iid}`);
    if (!pageOrAnchor)
      invariant(
        false,
        `saving share info error - pageOrAnchor is ${pageOrAnchor}`,
      );

    const [start, end] = pageOrAnchor.split('-');
    const data: ShareInfoData = {
      bid,
      iid,
      anchor: { start, end },
      ...parseShareURLQueryString(),
    };

    sessionStorage.setItem(this.keyName, JSON.stringify(data));
  }

  static save({ bid, iid, anchor, type, color }: any) {
    const [start, end] = anchor.split('-');
    const data: ShareInfoData = {
      bid,
      iid,
      anchor: { start, end },
      type,
      color,
    };
    sessionStorage.setItem(this.keyName, JSON.stringify(data));
  }

  static load() {
    const string = sessionStorage.getItem(this.keyName);

    if (!string) return null;

    return JSON.parse(string) as ShareInfoData;
  }
}

interface ShareInfoData {
  bid: string;
  iid: string;
  anchor: {
    start: string;
    end: string;
  };
  type: string | null;
  color: string | null;
}

import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalDialog, Icon } from '@ipf-dev/web-spindle-design-system';
import { useRecoilValue } from 'recoil';
import { BookMetaState } from '../../../../state';
import { CUSTOM_deleteAnnotationById } from '../../../../helpers';
import { RecordsType } from '../types';
import { RecordDeleteModalProps } from './types';
import Toast from '../../../commons/EpubToast';

export default function RecordDeleteModal({
  recordsType,
  selectedData,
  selections,
  onResetSelections,
  onSetIsEditing,
  onDeleteWords,
  isDeleting,
  onSetIsDeleting,
}: RecordDeleteModalProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const bookMeta = useRecoilValue(BookMetaState);

  const handleDeleteSelections = () => {
    const _ids = selectedData.map(({ _id }) => _id);

    if (recordsType !== 'word') {
      CUSTOM_deleteAnnotationById(_ids, bookMeta!.bid);
    } else {
      onDeleteWords(_ids);
    }

    onSetIsEditing();
    onResetSelections(new Set());
    onSetIsDeleting(false);

    const toastList = {
      bookmark: () =>
        Toast().success(
          t('epub_toast_delete_bookmark', { count: selectedData.length }),
        ),
      highlight: () =>
        Toast().success(
          t('epub_toast_delete_highlight', { count: selectedData.length }),
        ),
      word: () =>
        Toast().success(
          t('epub_toast_delete_word', { count: selectedData.length }),
        ),
    };
    toastList[recordsType]();
  };

  const getConfirmationModalTitle = (type: RecordsType) =>
    ({
      bookmark: t('epub_record_confirm_modal_title_bookmark'),
      highlight: t('epub_record_confirm_modal_title_highlight'),
      word: t('epub_record_confirm_modal_title_word'),
    }[type]);

  const getConfirmationModalContent = (type: RecordsType, count: number) =>
    ({
      bookmark: t('epub_record_confirm_modal_content_bookmark', {
        count,
      }),
      highlight: t('epub_record_confirm_modal_content_highlight', {
        count,
      }),
      word: t('epub_record_confirm_modal_content_word', {
        count,
      }),
    }[type]);

  return (
    <ModalDialog
      visible={isDeleting}
      onClose={() => onSetIsDeleting(false)}
      style={{ maxWidth: '48rem' }}
    >
      <ModalDialog.Template
        styleType="warning"
        Icon={<Icon.State styleType="trash" size="large" />}
        title={getConfirmationModalTitle(recordsType)}
        description={getConfirmationModalContent(recordsType, selections.size)}
        onCancelLabel={t('epub_record_confirm_modal_cancel_label')}
        onConfirmLabel={t('epub_record_confirm_modal_delete_label')}
        onCancel={() => onSetIsDeleting(false)}
        onConfirm={handleDeleteSelections}
      />
    </ModalDialog>
  );
}

import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import * as typography from '@tapas/typography';
import { BookMetaState } from '../../../../state';
import responsive from '../../../../styles/responsive';

const Title = styled.h3`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 700)};

  ${responsive.conditionForTablet} {
    width: 43vw;
  }

  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};
`;

function BookTitle() {
  const bookMeta = useRecoilValue(BookMetaState);

  return <Title>{bookMeta ? bookMeta.title : 'loading...'}</Title>;
}

export default BookTitle;

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { BookTocState, ViewerState } from '../../../../state';
import SentenceItem from '../ListItem/SentenceItem';
import useSearch from '../hooks/useSearch';
import { ExampleSentence, OriginalSentence } from '../ListItem/types';
import { Wrapper, SearchResultLabel } from './SearchResultList.style';
import SearchInfo from './templates/SearchInfo';
import { hasSpaces } from '../../../../utils';

type From = 'search' | 'word-details';

interface SearchResultListProps {
  value?: string;
  source: 'original' | 'example';
  query: string;
  onClose: Function;
  from: From;
}

function SearchResultList({
  value = '',
  source,
  query,
  onClose,
  from,
}: SearchResultListProps) {
  const bookToc = useRecoilValue(BookTocState);
  const viewer = useRecoilValue(ViewerState);
  const [sentences, setSentences] = useState<
    ExampleSentence[] | OriginalSentence[] | null
  >(null);

  const { t } = useTranslation();

  useEffect(() => {
    console.log('called', value, query, source, from, hasSpaces(query));
  }, []);

  useSearch(
    value,
    source,
    query,
    useCallback((data: any) => setSentences(data), [setSentences]),
  );

  const goToItemByURL = (url: string) => {
    const highlightColor = '52CF2955';
    viewer?.openBookWithURL(`${url}?highlight=${highlightColor}`);
    onClose();
  };

  if (from === 'search' && !value)
    return <SearchInfo type="default" source={source} />;

  if (source !== 'original' && hasSpaces(query))
    return <SearchInfo type="onlyWord" />;

  if (sentences && sentences.length === 0)
    return <SearchInfo type="notFound" query={value} />;

  return (
    <>
      {sentences && (
        <Wrapper from={from ?? 'search'}>
          {query && (
            <SearchResultLabel>
              {t('epub_search_result_count', {
                count: sentences.length,
              })}
            </SearchResultLabel>
          )}
          {source === 'original' &&
            sentences.map(({ url, text }: any, idx) => {
              if (typeof url === 'undefined') return null;

              const chapterNumber = url.split('/')[2];
              const tmp = bookToc.filter(
                (item) => item.address === chapterNumber,
              );

              let chapterName = '';
              try {
                chapterName = tmp[0].title;
              } catch (e) {
                chapterName = 'toc meta data is null';
              }

              return (
                <SentenceItem
                  key={`org-stc-${url || idx}`}
                  word={value || query}
                  sentence={`...${text}...`}
                  sentenceInfo={chapterName}
                  onClick={() => goToItemByURL(url)}
                />
              );
            })}
          {source === 'example' &&
            sentences.map(
              (
                {
                  sent_idx,
                  sentence,
                  ser_title,
                  lev_title,
                  book_title,
                  url,
                }: any,
                idx,
              ) => (
                <SentenceItem
                  key={`exm-stc-${sent_idx || idx}`}
                  word={query}
                  sentence={sentence}
                  sentenceInfo={`from ${ser_title} (${lev_title}): ${book_title}`}
                />
              ),
            )}
        </Wrapper>
      )}
    </>
  );
}

export default SearchResultList;

import React from 'react';
import ViewMode from '../../../blocks/ViewMode';

function CenterSide() {
  return (
    <>
      <ViewMode />
    </>
  );
}

export default CenterSide;

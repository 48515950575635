import styled from 'styled-components';
import { body } from '@tapas/typography';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  width: 19.2rem;
  display: none;
  z-index: 2;

  background: ${({ theme }) => theme.colorTokens.background.secondary};
  box-shadow: 0 2rem 4.8rem rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
`;

export const ActionButton = styled.div`
  padding: 1.6rem 2.4rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colorTokens.hierarchy.h4};
  cursor: pointer;
  user-select: none;

  :hover {
    background-color: ${({ theme }) => theme.colorTokens.hierarchy.h6};
  }

  :active {
    background-color: ${({ theme }) => theme.colorTokens.hierarchy.h5};
  }

  :first-child {
    border-top: none;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  :last-child {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  ${({ theme }) => body(theme.fonts.default, 1.6, 400)}
`;

import { useEffect } from 'react';
import { Theme } from 'buk-viewer';
import { useTheme } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { SettingsState, PageInfoState } from '../../../../../state';
import changeViewerTheme, { bukViewerPadding } from './changeViewerTheme';

export default function useViewerTheme() {
  const theme = useTheme();
  const settings = useRecoilValue(SettingsState);
  const pageInfo = useRecoilValue(PageInfoState);

  useEffect(() => {
    changeViewerTheme(settings?.theme.value as Theme, theme, bukViewerPadding);
  }, [theme, settings?.theme.value, pageInfo]);
}

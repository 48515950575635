import { useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { PopupOpenState, PageInfoState } from '../state';

const DELAY = 250;
const ALLOWED_TAP_TIME = 200;

function useToggleMenu() {
  const mouseDownTime = useRef<number>(0);
  const clickedCount = useRef<number>(0);
  const timeoutID = useRef<any>(null);
  const isContextMenuOpen = useRef<any>(null);
  const pageInfo = useRecoilValue(PageInfoState);
  const [popupOpen, setPopupOpen] = useRecoilState(PopupOpenState);

  useEffect(() => {
    if (popupOpen.contextMenu) {
      isContextMenuOpen.current = popupOpen.contextMenu;
    } else {
      setTimeout(() => {
        isContextMenuOpen.current = popupOpen.contextMenu;
      }, DELAY + 100);
    }
  }, [popupOpen.contextMenu]);

  useEffect(() => {
    const resetState = () => {
      clearTimeout(timeoutID.current);
      timeoutID.current = null;
      clickedCount.current = 0;
    };

    const toggleMenu = () => {
      if (isContextMenuOpen.current) {
        resetState();
        return;
      }

      if (clickedCount.current === 2) {
        resetState();
        return;
      }

      setPopupOpen((prevState: any) => ({
        ...prevState,
        header: !prevState.header,
        media: !prevState.media,
        progressBar: !prevState.progressBar,
      }));
    };

    const handleMouseDown = () => {
      clickedCount.current += 1;
      mouseDownTime.current = new Date().getTime();
    };

    const handleMouseUp = (e: any) => {
      const element = e.srcElement as HTMLElement;
      const doesClickedAnnotations =
        element.getAttribute('highlight-id') &&
        element.getAttribute('highlight-id') !== 'custom';
      if (doesClickedAnnotations) return;

      if (e.target.localName !== 'bukv-frame') {
        const isSelected = e.view.document.getSelection().toString().length > 0;
        if (isSelected) {
          resetState();
          return;
        }
      }

      const interval = new Date().getTime() - mouseDownTime.current;
      if (interval > ALLOWED_TAP_TIME) {
        resetState();
        return;
      }

      const { width } = window.visualViewport;
      const { clientX } = e;
      const ratio = clientX / width;
      const threshold = 0.2;

      if (ratio <= threshold) {
        resetState();
      } else if (ratio >= 1 - threshold) {
        resetState();
      } else {
        timeoutID.current = setTimeout(() => {
          toggleMenu();
          resetState();
        }, DELAY);
      }
    };

    document.querySelectorAll('bukv-frame').forEach((frame) => {
      const bukvFrame = frame as HTMLElement;
      bukvFrame.addEventListener('mousedown', handleMouseDown);
      bukvFrame.addEventListener('mouseup', handleMouseUp);
    });
    document.querySelectorAll('iframe').forEach((iframe) => {
      if (!iframe.contentDocument) return;
      iframe.contentDocument.addEventListener('mousedown', handleMouseDown);
      iframe.contentDocument.addEventListener('mouseup', handleMouseUp);
    });

    return () => {
      resetState();

      document.querySelectorAll('bukv-frame').forEach((frame) => {
        const bukvFrame = frame as HTMLElement;
        bukvFrame.removeEventListener('mousedown', handleMouseDown);
        bukvFrame.removeEventListener('mouseup', handleMouseUp);
      });
      document.querySelectorAll('iframe').forEach((iframe) => {
        if (!iframe.contentDocument) return;
        iframe.contentDocument.removeEventListener(
          'mousedown',
          handleMouseDown,
        );
        iframe.contentDocument.removeEventListener('mouseup', handleMouseUp);
      });
    };
  }, [setPopupOpen, pageInfo]);
}

export default useToggleMenu;

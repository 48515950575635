import React from 'react';
import { ThemeProvider } from 'styled-components';
import { light } from '../../../styles/theme';
import * as Icon from '../../../assets/icons';
import { Wrapper, IconBox, Label } from './ToastOverlayTemplate.style';

interface ToastOverlayTemplateProps {
  styleType: string;
  content: string;
  timeout: number;
}

export default function ToastOverlayTemplate({
  styleType,
  content,
  timeout,
}: ToastOverlayTemplateProps) {
  return (
    <ThemeProvider theme={light}>
      <Wrapper timeout={timeout}>
        <IconBox>
          {styleType === 'one' && <Icon.OneSideViewBig />}
          {styleType === 'both' && <Icon.BothSideViewBig />}
          {styleType === 'scroll' && <Icon.ScrollViewBig />}
        </IconBox>
        <Label>{content}</Label>
      </Wrapper>
    </ThemeProvider>
  );
}

import React from 'react';
import styled from 'styled-components';
import Viewer from '../../blocks/Viewer';

const Container = styled.main`
  width: 100%;
  height: 100%;
`;

function Main() {
  return (
    <Container>
      <Viewer />
    </Container>
  );
}

export default Main;

import { atom, selector } from 'recoil';
import { Settings } from 'buk-viewer';

export const SettingsState = atom<Settings | null>({
  key: 'Settings',
  default: null,
});

export const MultiColumnState = selector({
  key: 'MultiColumn',
  get: ({ get }) => get(SettingsState)?.multiColumn.value,
});

export const ThemeState = selector({
  key: 'Theme',
  get: ({ get }) => {
    const currentTheme = get(SettingsState)?.theme.value;
    window.theme = currentTheme;
    return currentTheme;
  },
});

export const PagingModeState = selector({
  key: 'PagingMode',
  get: ({ get }) => get(SettingsState)?.pagingMode.value,
});

export const CurrentViewModeState = selector({
  key: 'CurrentViewMode',
  get: ({ get }) => {
    if (get(PagingModeState) === 'scroll') {
      return 'scroll';
    }
    if (!get(MultiColumnState) ?? false) {
      return 'one';
    }
    if (get(MultiColumnState) ?? false) {
      return 'both';
    }
    return 'one';
  },
});

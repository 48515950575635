import React from 'react';
import { t } from 'i18next';
import {
  AddressChangeEvent,
  AnnotationChangedEvent,
  AnnotationCreatedEvent,
  AnnotationRemovedEvent,
  BookLoadErrorEvent,
  BookLoadEvent,
  BookmarkStateChangeEvent,
  HighlightClickEvent,
  ItemLoadErrorEvent,
  ItemLoadEvent,
  ItemLoadStartEvent,
  MediaOverlayPlayStateChangeEvent,
  PageInfoChangeEvent,
  PageTapEvent,
  SelectionChangeEvent,
  SettingsChangeEvent,
  BukViewer,
} from 'buk-viewer';
import Annotations from '../../../models/Annotations';
import ContextMenu from '../../../components/blocks/ContextMenu/view';
import {
  ALLOWED_TEXT_SELECTION_LENGTH,
  highlightColors,
} from '../viewer.config';
import {
  onAnnotationsChange,
  onMenuClick,
  onHighlightMenuClick,
} from './functions';
import { getStyleClass } from '../../../helpers';
import ShareInfo from '../../../models/ShareInfo';
import localforage from '../../localforage';
import Toast from '../../../components/commons/EpubToast';

export function onBookLoad(
  event: BookLoadEvent,
  setters: any,
  refs: any,
  viewer: BukViewer,
) {
  console.log('book loaded: ', event.book);
  setters.setBookInfo(event.book);

  if (refs.annotationsModel.current) {
    refs.annotationsModel.current.destroy();
  }

  refs.annotationsModel.current = new Annotations(event.book.meta.bid, {
    onChange: (_annotations: any) =>
      onAnnotationsChange(_annotations, viewer, (value: any) =>
        setters.setAnnotations(value),
      ),
  });

  refs.contextMenuRef.current = new ContextMenu(
    document.querySelector('#context-menu'),
    Object.entries(highlightColors),
    {
      onHighlightMenuClick: (data: any) => onHighlightMenuClick(data),
      onMenuClick: (data: any) => onMenuClick(data, refs, setters),
      onChange: (flag: boolean) =>
        setters.setPopupOpen((prev: any) => ({
          ...prev,
          contextMenu: flag,
        })),
    },
  );
}

export function onBookLoadError(event: BookLoadErrorEvent) {
  console.log('book load error: ', event);
}

export function onAddressChange(event: AddressChangeEvent, hasBook: boolean) {
  console.log('address change: ', event);

  if (hasBook) {
    const {
      address: { bid, iid, anchor },
    } = event;

    const currentAddressURL = `${bid}/${iid}/${anchor}`.replace(
      /\/undefined/g,
      '',
    );

    localforage.setItem(`last-address.${bid}`, currentAddressURL);
  }
}

export function onItemLoadStart(event: ItemLoadStartEvent) {
  console.log('item load start: ', event);

  // loaderView.update(true);
}

export function onItemLoad(event: ItemLoadEvent, setters: any) {
  console.log('item loaded: ', event);
  setters.setItemInfo(event);

  // FIXME
  const data = ShareInfo.load();
  if (!data) return;
  const { bid, iid, anchor, color, type } = data;
  const isSameBid = `@${event.address.bid}` === bid;
  const isSameIid = event.address.iid === iid;

  if (isSameBid && isSameIid && type !== 'bookmark') {
    const viewer: BukViewer = document.querySelector('buk-viewer')!;

    viewer.createHighlight(
      `${bid}/${iid}/${anchor.start}-${anchor.end}`,
      getStyleClass(color),
      {
        expandToWord: false,
        emitEvent: false,
      },
    );
  }

  // loaderView.update(false);
}

export function onItemLoadError(event: ItemLoadErrorEvent) {
  console.log('item load error: ', event);

  // loaderView.update(false);
}

export function onSettingsChange(event: SettingsChangeEvent, setters: any) {
  setters.setSettings(event.settings);
}

export function onPageInfoChange(event: PageInfoChangeEvent, setters: any) {
  setters.setPageInfo(event);
  console.log(`page info changed: ${event.page} / ${event.pageCount}`, event);
}

export function onBookmarkStateChange(
  event: BookmarkStateChangeEvent,
  setters: any,
) {
  console.log('bookmark state changed', event.isActive);

  setters.setBookmark(event.isActive);
}

export function onMediaOverlayPlayStateChange(
  event: MediaOverlayPlayStateChangeEvent,
  setters: any,
) {
  setters.setMediaInfo((prevState: any) => ({
    ...prevState,
    isPlaying: event.isPlaying,
  }));
}

export function onPageTap(event: PageTapEvent) {
  console.log('page tap: ', event);
}

export function onPageChangeBlocked() {
  window.alert('미리보기 끝');
}

export function onSelectionChange(
  event: SelectionChangeEvent,
  setters: any,
  contextMenuRef: any,
) {
  console.log('selection changed', event);

  const selection = event.doc.getSelection();
  if (selection) {
    setters.setSelectedText({
      text: selection.toString(),
      url: event.selection?.url ?? '',
    });

    if (selection.toString().length > ALLOWED_TEXT_SELECTION_LENGTH) {
      Toast().info(
        t('epub_text_selection_length_toast', {
          number: ALLOWED_TEXT_SELECTION_LENGTH.toLocaleString(),
        }),
      );

      const viewer = document.querySelector('buk-viewer') as BukViewer;
      viewer!.clearSelection();
    }
  } else {
    setters.setSelectedText({ text: '', url: '' });
  }

  const data = {
    event,
    contextMenuRef,
  };

  const nextEvent = new CustomEvent('sendSelectionInfo', { detail: data });
  event.doc.dispatchEvent(nextEvent);
}

export function onHighlightClick(
  event: HighlightClickEvent,
  contextMenuRef: any,
) {
  console.log('highlight clicked', event);

  const userHighlight = event.highlights.find((highlight) => {
    return Object.keys(highlightColors).includes(
      highlight.annotation.styleClass ?? '',
    );
  });

  if (!userHighlight) {
    return;
  }

  const viewer: BukViewer = document.querySelector('buk-viewer')!;
  const canPlayMediaOverlay = viewer.canPlayMediaOverlayFromRange(
    userHighlight.range,
  );

  contextMenuRef.current.update({
    selectedRange: userHighlight.range,
    annotation: userHighlight.annotation,
    canPlayMediaOverlay,
  });

  contextMenuRef.current.updatePositionByOffsetAndRange(
    event.offsetX,
    event.offsetY,
    userHighlight.range,
  );

  setTimeout(() => {
    contextMenuRef.current.show();
  }, 200);
}

export function onAnnotationCreated(event: AnnotationCreatedEvent, refs: any) {
  console.log('annotation created', event);
  console.log('context', refs.contextRef);

  refs.annotationsModel.current!.create(event.annotation, refs.contextRef);

  if (event.mergedAnnotations.length > 0) {
    refs.annotationsModel.current!.delete(
      event.mergedAnnotations.map((annotation) => annotation.url),
    );
  }
}

export function onAnnotationChanged(
  event: AnnotationChangedEvent,
  annotationsModel: React.RefObject<Annotations>,
) {
  console.log('A annotation changed', event);
  annotationsModel.current!.update(event.annotation);
}

export function onAnnotationRemoved(
  event: AnnotationRemovedEvent,
  annotationsModel: React.RefObject<Annotations>,
) {
  console.log('annotation removed', event);

  annotationsModel.current!.delete(
    event.annotations.map((annotation) => annotation.url),
  );
}

import React from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { PopupOpenState } from '../../../state';
import PlayButton from './PlayButton';
import MediaController from './MediaController';

const Wrapper = styled.div`
  position: fixed;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
`;

interface MediaProps {
  visible: boolean;
}

function Media({ visible }: MediaProps) {
  const [popupOpen, setPopupOpen] = useRecoilState(PopupOpenState);

  const onClose = () => {
    setPopupOpen((prev) => ({ ...prev, playButton: !popupOpen.playButton }));
  };

  if (!visible) return null;

  return (
    <Wrapper>
      {popupOpen.playButton && <PlayButton onClose={onClose} />}
      {!popupOpen.playButton && <MediaController onClose={onClose} />}
    </Wrapper>
  );
}

export default Media;

import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import * as typography from '@tapas/typography';
import { Icon } from '@ipf-dev/web-spindle-design-system';

const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
`;

const makeFontStyle = (font: string) => `
  font-weight: 400;
  font-family: ${font};
`;

const Label = styled.span<{ font: string }>`
  display: inline-block;
  padding-top: 0.2rem;
  color: ${({ theme }) => theme.colorTokens.accent.default};
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 500)};
  ${({ font }) => font !== 'default' && makeFontStyle(font)}
`;

interface SettingFontsButtonProps {
  currentFontFace: string;
  onClick: MouseEventHandler;
}

function SettingFontsButton({
  currentFontFace,
  onClick,
}: SettingFontsButtonProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Button onClick={onClick}>
      <Label font={currentFontFace}>
        {currentFontFace === 'default'
          ? t('epub_view_setting_original_font')
          : currentFontFace}
      </Label>
      <Icon.Arrow
        styleType="chevron"
        direction="right"
        color={theme.colorTokens.accent.default}
      />
    </Button>
  );
}

export default SettingFontsButton;

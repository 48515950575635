import { BukViewer } from 'buk-viewer';
import * as H from './handlers';
import setConfig from './viewer.config';
import localforage from '../localforage';
import ShareInfo from '../../models/ShareInfo';

require('buk-viewer');

export default async function configureViewer(
  url: string,
  setters: any,
  refs: any,
  epubid: string,
  hasBook?: boolean,
) {
  const viewer: BukViewer = document.querySelector('buk-viewer')!;
  setters.setViewer(viewer);

  const viewerConfig = viewer.init(setConfig(hasBook));
  console.log('viewer initialized: ', viewerConfig);

  viewer.addEventListener('bookLoad', (event: any) => {
    H.onBookLoad(event.detail, setters, refs, viewer);
  });

  viewer.addEventListener('bookLoadError', (event: any) => {
    H.onBookLoadError(event.detail);
  });

  viewer.addEventListener('addressChange', (event: any) => {
    H.onAddressChange(event.detail, hasBook ?? false);
  });

  viewer.addEventListener('itemLoadStart', (event: any) => {
    H.onItemLoadStart(event.detail);
  });

  viewer.addEventListener('itemLoad', (event: any) => {
    H.onItemLoad(event.detail, setters);
  });

  viewer.addEventListener('itemLoadError', (event: any) => {
    H.onItemLoadError(event.detail);
  });

  viewer.addEventListener('settingsChange', (event: any) => {
    H.onSettingsChange(event.detail, setters);
  });

  viewer.addEventListener('pageInfoChange', (event: any) => {
    H.onPageInfoChange(event.detail, setters);
  });

  viewer.addEventListener('bookmarkStateChange', (event: any) => {
    H.onBookmarkStateChange(event.detail, setters);
  });

  viewer.addEventListener('mediaOverlayPlayStateChange', (event: any) => {
    H.onMediaOverlayPlayStateChange(event.detail, setters);
  });

  viewer.addEventListener('pageTap', (event: any) => {
    H.onPageTap(event.detail);
  });

  viewer.addEventListener('pageChangeBlocked', () => {
    H.onPageChangeBlocked();
  });

  viewer.addEventListener('selectionChange', (event: any) => {
    H.onSelectionChange(event.detail, setters, refs.contextMenuRef);
  });

  viewer.addEventListener('highlightClick', (event: any) => {
    H.onHighlightClick(event.detail, refs.contextMenuRef);
  });

  viewer.addEventListener('annotationCreated', (event: any) => {
    H.onAnnotationCreated(event.detail, refs);
  });

  viewer.addEventListener('annotationChanged', (event: any) => {
    H.onAnnotationChanged(event.detail, refs.annotationsModel);
  });

  viewer.addEventListener('annotationRemoved', (event: any) => {
    H.onAnnotationRemoved(event.detail, refs.annotationsModel);
  });

  const lastAddress = (await localforage.getItem(
    `last-address.${epubid.replace('@', '')}`,
  )) as string;
  const hasSharedURL = !!sessionStorage.getItem('shared-url');

  if (!hasSharedURL && lastAddress) {
    viewer.openBookWithURL(lastAddress);
  } else {
    viewer.openBookWithURL(url);
  }
}

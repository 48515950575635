import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import ToastOverlayTemplate from './ToastOverlayTemplate';

class ToastOverlay {
  static cancel?: Function;

  static oneSideView(content: string, timeout?: number) {
    this.toast('one', content, timeout);
  }

  static bothSideView(content: string, timeout?: number) {
    this.toast('both', content, timeout);
  }

  static scrollView(content: string, timeout?: number) {
    this.toast('scroll', content, timeout);
  }

  static toast(styleType: string, content: string, timeout = 2000) {
    if (this.cancel) {
      this.cancel();
    }

    const container = document.createElement('div');
    document.body.appendChild(container);

    const close = () => {
      unmountComponentAtNode(container);
      container.parentNode?.removeChild(container);
    };

    this.cancel = close;

    render(
      <ToastOverlayTemplate
        styleType={styleType}
        content={content}
        timeout={timeout}
      />,
      container,
    );

    setTimeout(() => {
      close();
    }, timeout + 600);
  }
}

export default ToastOverlay;

import { DefaultTheme } from 'styled-components';
import { buildSpindleTheme } from '@ipf-dev/web-spindle-design-system';
import epubColorTokens from './epubColorTokens';
import { addAlpha } from '../helpers';

const custom = {
  // only use in epub
  backgroundLighten: '#FDFBF5',
  backgroundDarken: '#16191C',
  mediaOverlay: `${addAlpha('#90D279', 0.4)}`,
  textSelection: `${addAlpha('#007AFF', 0.2)}`,
  middleDivider: '#ffffff1f',

  // will be deleted
  popover: '#3B3E40',
  textColorLighten: '#212529',
  textColorDarken: '#F1F3F5',
};

export const light: DefaultTheme = {
  custom,
  epubColorTokens,
  ...buildSpindleTheme({ colorTokens: { mode: 'light' } }),
};

export const dark: DefaultTheme = {
  custom,
  epubColorTokens,
  ...buildSpindleTheme({ colorTokens: { mode: 'dark' } }),
};

interface ThemeList {
  [key: string]: DefaultTheme;
}

export const themeList: ThemeList = {
  light,
  dark,
};

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { PopupOpenState, ThemeState } from './state';
import {
  useBukio,
  useDesktopContextMenu,
  useMobileContextMenu,
  useToggleMenu,
} from './hooks';
import GlobalStyle from './styles/global-styles';
import { themeList } from './styles/theme';
import { LayoutRoot, Header, Main, Footer } from './components/layouts';

function App() {
  const theme = useRecoilValue(ThemeState);
  const popupOpen = useRecoilValue(PopupOpenState);

  useBukio();
  useDesktopContextMenu();
  useMobileContextMenu();
  useToggleMenu();

  return (
    <ThemeProvider theme={themeList[theme?.toString() ?? 'light']}>
      <GlobalStyle />
      <LayoutRoot>
        <Header visible={popupOpen.header} />
        <Main />
        <Footer />
      </LayoutRoot>
    </ThemeProvider>
  );
}

export default App;

import styled, { css } from 'styled-components';
import { body } from '@tapas/typography';

const lightInputStyle = (theme: any) => css`
  input {
    color: ${theme.colors.gray6};
    background-color: #fff;
  }
`;

const darkInputStyle = css`
  input {
    color: #fff;
    background-color: #323537;
  }
  path {
    fill: rgba(255, 255, 255, 0.6);
  }
  svg:hover {
    path {
      fill: rgba(255, 255, 255, 0.87);
    }
  }
  svg:active {
    path {
      fill: rgba(255, 255, 255, 0.16);
    }
  }
`;

export const Form = styled.form`
  display: flex;
  height: 5.8rem;
  margin: 3.2rem;
  margin-bottom: 0.8rem;

  @media screen and (max-width: 500px) {
    label {
      font-size: 1.2rem;
    }
  }

  ${({ theme }) =>
    theme.colorTokens.mode === 'light'
      ? lightInputStyle(theme)
      : darkInputStyle}
`;

const lightSubmitButtonStyle = (theme: any) => css`
  :hover {
    background: ${theme.colors.gray1};
  }
  :active {
    background: ${theme.colors.gray2};
  }
  :disabled {
    background: ${theme.colors.gray1};
  }
`;

const darkSubmitButtonStyle = css`
  :hover {
    background: rgba(255, 255, 255, 0.12);
  }
  :active {
    background: rgba(0, 0, 0, 0.1);
  }
  :disabled {
  }
`;

export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  display: inline-block;
  width: 10rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray3};
  border-radius: 0.8rem;
  color: ${({ theme }) =>
    theme.colorTokens.mode === 'light' ? '#000' : '#fff'};

  ${({ theme }) => body(theme.fonts.default, 1.4, 700)};
  ${({ theme }) =>
    theme.colorTokens.mode === 'light'
      ? lightSubmitButtonStyle(theme)
      : darkSubmitButtonStyle}
`;

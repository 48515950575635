import invariant from 'tiny-invariant';
import { BukViewer } from 'buk-viewer';

export * from './url';
export * from './annotations';
export * from './colors';
export { default as initDevtools } from './devtools';

export const formatUnixTime = (createdAt: number) =>
  new Date(createdAt)
    .toLocaleDateString()
    .replace(/(\s*)/g, '')
    .replace(/\.$/, '');

export const renderRateNumber = (rateNumber: number) => {
  if (
    rateNumber === 0.5 ||
    rateNumber === 1 ||
    rateNumber === 1.5 ||
    rateNumber === 2
  )
    return `${rateNumber.toFixed(1)}x `;

  return `${rateNumber.toFixed(2)}x `;
};

export const getViewer = () => {
  const viewer = document.querySelector('buk-viewer') as BukViewer;
  invariant(viewer, `buk-viewer is not rendered - viewer: ${viewer}`);
  return viewer;
};

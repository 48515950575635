import React from 'react';
import styled, { useTheme } from 'styled-components';
import * as Icon from '../../../../assets/icons';

const Button = styled.button``;

function ExitButton() {
  const theme = useTheme();

  const handleExitButton = () => {
    // 학습 기록 저장 API가 추가되야함.
    window.close();
  };

  return (
    <Button onClick={handleExitButton}>
      <Icon.Exit fill={theme.colorTokens.icon.primary} />
    </Button>
  );
}

export default ExitButton;

import React, { useRef, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { createPopper } from '@popperjs/core';
import * as typography from '@tapas/typography';
import { useMediaQuery } from 'react-responsive';
import * as Icon from '../../../assets/icons';
import { useOnClickOutside } from '../../../hooks';
import { mobileRightSideMaxWidth } from '../../../styles/responsive';

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  width: 20rem;
  box-shadow: -0.3rem 1rem 4rem rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colorTokens.background.secondary};
  color: ${({ theme }) => theme.colorTokens.hierarchy.h1};

  &:after {
    content: '';
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 20px 20px;
    border-color: ${({ theme }) => theme.colorTokens.background.secondary}
      transparent;
    display: block;
    width: 0;
    z-index: 2;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 1.6rem;
  justify-content: center;
  align-items: center;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colorTokens.hierarchy.h4};
`;

const PrevButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  left: 1.6rem;
`;

const Title = styled.span`
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 400)};
`;

const ItemBox = styled.div`
  padding: 0 1.6rem 2.4rem 1.6rem;
  cursor: pointer;

  &:nth-child(2) {
    padding-top: 1.6rem;
  }
`;

const makeFontStyle = (font: string) => `
  font-weight: 400;
  font-family: ${font};
`;

const Label = styled.span<{ font: string; isSelected: boolean }>`
  ${({ theme }) => typography.body(theme.fonts.default, 1.6, 500)};
  ${({ theme, isSelected }) =>
    isSelected && `color: ${theme.colorTokens.accent.default}`};
  ${({ font }) => font !== 'default' && makeFontStyle(font)}
`;

interface SettingsFontsPopoverProps {
  fonts: string[];
  currentFont: string;
  onChangeFonts: Function;
  onClose: Function;
  onToggleSettings: Function;
}

function SettingsFontsPopover({
  fonts,
  currentFont,
  onChangeFonts,
  onClose,
  onToggleSettings,
}: SettingsFontsPopoverProps) {
  const theme = useTheme();
  const ref = useRef(null);

  const { t } = useTranslation();

  useOnClickOutside(ref, onClose);
  const isLeanHeader = useMediaQuery({ query: mobileRightSideMaxWidth });

  useEffect(() => {
    const reference = document.getElementById(
      isLeanHeader ? 'more-reference' : 'settings-reference',
    );
    const popper = document.getElementById('settings-fonts-popper');
    if (!reference || !popper) return;
    createPopper(reference, popper, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 40],
          },
        },
      ],
    });
  });

  return (
    <>
      <Overlay />
      <Wrapper id="settings-fonts-popper" ref={ref}>
        <Header>
          <PrevButton
            onClick={() => {
              onToggleSettings();
              onClose();
            }}
          >
            <Icon.ArrowChevronLeft fill={theme.colorTokens.icon.primary} />
          </PrevButton>
          <Title>{t('epub_view_setting_font_popover_title')}</Title>
        </Header>
        {['default', ...fonts].map((font) => (
          <ItemBox
            key={`epub-fonts-list${font}`}
            onClick={() => onChangeFonts(font)}
          >
            <Label font={font} isSelected={font === currentFont}>
              {font === 'default' ? t('epub_view_setting_original_font') : font}
            </Label>
          </ItemBox>
        ))}
      </Wrapper>
    </>
  );
}

export default SettingsFontsPopover;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ViewerState, ThemeState, BookMetaState } from '../../../../state';
import * as Icon from '../../../../assets/icons';
import Toast from '../../../commons/EpubToast';
import RecordPopover from '../RecordPopover/RecordPopover';
import {
  CUSTOM_changeHighlightColorById,
  CUSTOM_deleteAnnotationById,
  makeShareURL,
  AnnotationInfo,
} from '../../../../helpers';
import { copyToClipboard, parseTextBySomeLength } from '../../../../utils';
import {
  Container,
  SelectBox,
  CheckboxIconStyle,
  Wrapper,
  MainBox,
  IconBox,
  ColorCircle,
  InfoBox,
  TextLabel,
  ChapterLabel,
  PageLabel,
  DateLabel,
  MoreIconBox,
  Divider,
} from './AnnotationItem.style';
import { AnnotationItemProps } from '../types';

function AnnotationItem({
  recordsType,
  _id,
  idx,
  onSelect,
  text,
  url,
  styleClass,
  chapter,
  page,
  createdAt,
  isEditing,
  isSelected,
  onClose,
}: AnnotationItemProps) {
  const viewer = useRecoilValue(ViewerState);
  const theme = useRecoilValue(ThemeState);
  const bookMeta = useRecoilValue(BookMetaState);

  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const { t } = useTranslation();

  const jumpPageByURL = () => {
    viewer?.openBookWithURL(url);
    onClose();
  };

  const onDeleteAnnotation = () => {
    CUSTOM_deleteAnnotationById(_id, bookMeta!.bid);
    if (recordsType === 'bookmark')
      Toast().success(t('epub_toast_delete_bookmark', { count: 1 }));
    if (recordsType === 'highlight')
      Toast().success(t('epub_toast_delete_highlight', { count: 1 }));
  };

  const onChangeHighlightColor = (color: string) => {
    CUSTOM_changeHighlightColorById(_id, url, color);
  };

  return (
    <Container>
      {isEditing && (
        <SelectBox>
          {!isSelected && (
            <Icon.CheckboxOff
              fill={theme === 'light' ? 'white' : '#323537'}
              stroke={theme === 'light' ? '#ADB5BD' : 'rgba(255,255,255,0.6)'}
              style={{ cursor: 'pointer' }}
              onClick={isEditing ? (e) => onSelect(e, idx) : undefined}
            />
          )}
          {isSelected && (
            <CheckboxIconStyle>
              <Icon.CheckboxOn
                style={{ cursor: 'pointer' }}
                onClick={isEditing ? (e) => onSelect(e, idx) : undefined}
              />
            </CheckboxIconStyle>
          )}
        </SelectBox>
      )}
      <Wrapper
        isEditing={isEditing}
        onClick={isEditing ? (e) => onSelect(e, idx) : undefined}
      >
        <MainBox>
          <IconBox>
            {recordsType === 'bookmark' && <Icon.BookmarkFill />}
            {recordsType === 'highlight' && (
              <ColorCircle styleClass={styleClass} />
            )}
          </IconBox>
          <InfoBox>
            {recordsType === 'bookmark' && (
              <div>
                <TextLabel isEditing={isEditing} onClick={jumpPageByURL}>
                  {text.length > 200 &&
                    `${parseTextBySomeLength(text, 1, 200)}...`}
                  {text.length > 200 || text}
                </TextLabel>
              </div>
            )}
            {recordsType === 'highlight' && (
              <div>
                <TextLabel isEditing={isEditing} onClick={jumpPageByURL}>
                  {text}
                </TextLabel>
              </div>
            )}
            {recordsType === 'bookmark' && (
              <ChapterLabel>{chapter}</ChapterLabel>
            )}
            {page && (
              <PageLabel>{t('epub_record_bookmark_page', { page })}</PageLabel>
            )}
            {page && <Divider />}
            <DateLabel>{createdAt}</DateLabel>
          </InfoBox>
          <MoreIconBox
            id={`popover-reference-${idx}`}
            style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
          >
            {!isEditing && (
              <Icon.More
                fill={theme === 'light' ? '#212529' : 'rgba(255,255,255,0.6'}
                onClick={() => setPopoverOpen(!isPopoverOpen)}
              />
            )}
          </MoreIconBox>
        </MainBox>
        {isPopoverOpen && (
          <RecordPopover
            idx={idx}
            recordsType={recordsType}
            styleClass={styleClass}
            onClose={() => setPopoverOpen(!isPopoverOpen)}
            onChangeColor={onChangeHighlightColor}
            actions={[
              {
                name: t('epub_record_box_button_more_action_sheet_delete'),
                onAct: onDeleteAnnotation,
              },
              {
                name: t('epub_record_box_button_more_action_sheet_share'),
                onAct: () => {
                  const browserURL = makeShareURL({
                    url,
                    type: recordsType,
                    styleClass,
                  } as AnnotationInfo);
                  copyToClipboard(browserURL) &&
                    Toast().success(t('epub_toast_share'));
                },
              },
            ]}
          />
        )}
      </Wrapper>
    </Container>
  );
}

export default AnnotationItem;

import { createGlobalStyle } from 'styled-components';
import { makeCustomScrollBarStyle } from '../components/commons/CustomScrollBar';

const GlobalStyle = createGlobalStyle`
   * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        
    }

    html {
      font-size: 10px;
      font-family: "Noto Sans KR", san-serif;
    }
    
    button {
      border: 0;
      background-color: transparent;

      &:hover {
        cursor: pointer;
      }
    }

    li {
      list-style: none;
    }

    ${({ theme }) =>
      makeCustomScrollBarStyle(
        theme.colorTokens.hierarchy.h4,
        theme.colorTokens.background.primary,
      )}
`;

export default GlobalStyle;
